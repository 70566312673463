export const testYves = (s) => {
  return () => {
	  console.log("WOOOOOOW!!!!! --- " + s);
   /* return sessionApi.logout().then(() => {
      sessionService.deleteSession();
      sessionService.deleteUser();
      history.push('/login');
    }).catch(err => {
      throw (err);
    });*/
  };
};

export const generateIPForREST = () => {


    var numb = Math.floor(Math.random() * 3);

    if (numb == 2)
    {
      var  ip = 'https://api2.debyoo.com/';
    }
    else if (numb == 1)
    {
      var  ip = 'https://api1.debyoo.com/';
    }
    else
    {
      var  ip = 'https://api0.debyoo.com/';
    }

    console.log("IP: " + ip);


   return ip;
}
