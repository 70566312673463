/*import React from 'react';
import { combineReducers, createStore, applyMiddleware } from "redux";
import rootReducer from "./rootReducer";
import thunk from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension';


export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
*/


import thunk from "redux-thunk";
import { composeWithDevTools } from 'redux-devtools-extension';

import { createStore,compose, applyMiddleware } from "redux";
import rootReducer from "./rootReducer";

function configureStore(state = { } ) {
  return createStore(rootReducer,state,composeWithDevTools(applyMiddleware(thunk)));
}

export default configureStore;
