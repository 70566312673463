import React from "react";
import PropTypes from 'prop-types';
//import { connect } from "react-redux";

import {  Link, withRouter } from 'react-router-dom';

import { inject, observer, Provider } from "mobx-react";
import { withTranslation, Translation, } from 'react-i18next';
import {BrowserView,MobileView,TabletView,} from 'react-device-detect';

import { Gradient } from 'react-gradient';

import {generateIPForREST} from "../../../actions";

//import { Checkbox } from '../../utils/Checkbox';

import SigninForm from "../../forms/SigninForm";
import MetaTags from 'react-meta-tags';
//import { login } from "../../actions/auth";

import Spinner from 'react-activity/lib/Spinner';
import 'react-activity/lib/Spinner/Spinner.css';

import { Fade } from 'react-slideshow-image';

const fadeImages = [
  'https://s3.amazonaws.com/debyooinc20.img/home/home_img_stories.png',
  'https://s3.amazonaws.com/debyooinc20.img/home/Publish_SP4_06.png',
  'https://s3.amazonaws.com/debyooinc20.img/home/home_img_feels4.png'
];

const fadeProperties = {
  duration: 3500,
  transitionDuration: 500,
  infinite: true,
  arrows: false,
  indicators: false
}


class Checkbox extends React.Component {

  static propTypes = {
    hasError: PropTypes.bool,
 //   id: PropTypes.string.isRequired,
    indeterminate: PropTypes.bool,
  //  label: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['default', 'switch']),
  };
  static defaultProps = {
    hasError: false,
    indeterminate: undefined,
    type: 'default',
  };

  componentDidMount() {
    // Apply the indeterminate attribute of the checkbox input
    this.selector.indeterminate = this.props.indeterminate;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.indeterminate !== this.props.indeterminate) {
      this.selector.indeterminate = this.props.indeterminate;
    }
  }

  render() {
    const { id, label, type, indeterminate, hasError, ...inputProps } = this.props;
    const checkboxClassname = `
      m-checkbox
      ${type === 'switch' && 'm-checkbox--switch'}
      ${hasError && 'm-checkbox--has-error'}
    `;

    const inputClassname = `
      m-checkbox__input
      ${type === 'switch' && 'm-checkbox--switch__input'}
      ${hasError && 'm-checkbox--has-error__input'}
    `;

    const labelClassname = `
      m-checkbox__label
      ${type === 'switch' && 'm-checkbox--switch__label'}
    `;

    return (
      <div className={checkboxClassname}>
        <input style={{width:20, height:20}}
          type="checkbox"
          className={inputClassname}
          ref={el => (this.selector = el)}
          id={id}
          {...inputProps}
        />
          <label className={labelClassname} htmlFor={id}>{label}</label>
      </div>
    );
  }
}

//@inject("appStore")

class SignupPage extends React.Component {

    constructor(props) {

          super(props);



   	 this.state = {
		  name: '',
		 email: '',
		 password: '',
             showNameError: 'none',
		showEmailTakenError: 'none',
		showEmailInvalidError: 'none',
		showPasswordError: 'none',
		showPasswordTooShortError: 'none',
		showTermsError: 'none',
		 agreedTerms: false,
		 displaySignButtons: true,
		 currentYear: new Date().getFullYear()
   	};

       this.handleChangeName= this.handleChangeName.bind(this);
	this.handleChangeEmail = this.handleChangeEmail.bind(this);
	this.handleChangePassword = this.handleChangePassword.bind(this);
	this.handleChangeAgreedTerms = this.handleChangeAgreedTerms(this);
	
	   this.onSubmit = this.onSubmit.bind(this);
	//this.handleSubmit = this.handleSubmit.bind(this);

     }


    setLocale(lang) {

  	  console.log("Locale Lang:" +lang)
           this.props.appStore.locale = lang;
    }


 /* submit = data =>
    this.props.login(data).then(() => this.props.history.push("/dashboard"));
*/

	

    handleChangeName(event) {
            this.setState({name: event.target.value});
   }

    handleChangeEmail(event) {
            this.setState({email: event.target.value});
   }

   handleChangePassword(event) {
           this.setState({password: event.target.value});
  }
  
  isEmail(email) {
     return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(email);
  }
  
 onSubmit(event) {
        event.preventDefault();

        this.setState({
          displaySignButtons: false
        });
		
	var cb = document.getElementById("termsCB").checked;
	
	var isValid =  true;
	isValid = isValid && cb;
	
	if (!isValid)
	{
            this.setState({
		 showTermsError: 'block',
		 displaySignButtons: true
           });
		   
	       setTimeout (function(){
	               this.setState({
	   		     showTermsError: 'none'
	              });
	       }.bind(this),3000);
	}
	else 
	{
		var isNameValid = this.state.name.length > 0 ? true : false;
		isValid = isValid && isNameValid;
		
		if (!isValid)
		{
		            this.setState({
				  showNameError: 'block',
				 displaySignButtons: true
		           });
				   
			       setTimeout (function(){
			               this.setState({
			   		     showNameError: 'none'
			              });
			       }.bind(this),3000);
		}
		else
		{
			
			var isEmailValid = this.isEmail(this.state.email);
			isValid = isValid && isEmailValid;
			
			if (!isValid)
			{
			            this.setState({
					  showEmailInvalidError: 'block',
					 displaySignButtons: true
			           });
				   
				       setTimeout (function(){
				               this.setState({
				   		     showEmailInvalidError: 'none'
				              });
				       }.bind(this),3000);
			}
			else
			{
				  var isPasswordValid = this.state.password.length > 0 ? true : false;
				  isValid = isValid && isPasswordValid;
				  
		  		if (!isValid)
		  		{
		  		            this.setState({
		  				  showPasswordError: 'block',
		  				 displaySignButtons: true
		  		           });
				   
		  			       setTimeout (function(){
		  			               this.setState({
		  			   		     showPasswordError: 'none'
		  			              });
		  			       }.bind(this),3000);
		  		}
				else 
				{
  				          var isPasswordNotShort = this.state.password.length > 5 ? true : false;
  				          isValid = isValid && isPasswordNotShort;
						  
		  		  		if (!isValid)
		  		  		{
		  		  		            this.setState({
		  		  				  showPasswordTooShortError: 'block',
		  		  				 displaySignButtons: true
		  		  		           });
				   
		  		  			       setTimeout (function(){
		  		  			               this.setState({
		  		  			   		     showPasswordTooShortError: 'none'
		  		  			              });
		  		  			       }.bind(this),3000);
		  		  		}
						else
						{
							this.confirmOnSubmit();
						}
				}
			}
		}
	}
		
  }
  
    async confirmOnSubmit() {	
		

	        try {
	           
			   
			       console.log("submit");

		            // var ip = 'https://www.debyoo.com/';

		            //  var ip = 'http://localhost:3000/';

		            var ip = generateIPForREST();

		             let response = await fetch(ip+'signup', {
		                                     method: 'POST',
		                                     headers: {
		                                       'Accept': 'application/json',
		                                       'Content-Type': 'application/json',
		                                     },
		                                     body: JSON.stringify({
                                                     typef: 'ajx',
				                         req: 'api',
		                                       user:{
							      name: this.state.name,
		                                         email: this.state.email,
		                                         password: this.state.password,
		                                       }
		                                     })
		                                   });
		             let res = await response.json();

		             if (response.status >= 200 && response.status < 300) {

		                       if (res.status == "100")
		                       {
		                              console.log(">>> 100 HERE >>> " + JSON.stringify(res));

		                             /* this.reactivateStep = 1;
		                               this.responseData = res;

		                              this.setState({
		                                virtualjobvar: 'a',
		                                global_account_active_status: res.global_account_active_status
		                              });*/
		                       }
		                        else if (res.status == "200")
		                        {

		                               console.log(">>> 200 HERE >>> " + JSON.stringify(res));
							 
						
							 
							 
					       this.props.login({type: 'USER_LOGGED_IN', user: res});
				   
				   

		                               this.setState({displaySignButtons: true});

		                               localStorage.setItem("jwtToken", res.jwt_token);
							 
				
							 
						    sessionService.saveSession({token: res.jwt_token, authenticated: true })
						         .then(() => {
						           sessionService.saveUser(res)
						           .then(() => {
				                    
									
									this.props.history.push('/welcome');
									
									
						           }).catch(err => console.error(err));
						         }).catch(err => console.error(err));
							 
	
		                       }
		                       else
		                       {
				                          console.log(">>> Other HERE >>> " + JSON.stringify(res));
				                          console.log(">>> response >>> " + JSON.stringify(response));
										 
				                      if (res.error == "name")
				 			  {
				 				  this.setState({password: '', showNameError: 'block', displaySignButtons: true});
				 				  setTimeout(function(){
				 				  	this.setState({showNameError: 'none'});
				 				  }.bind(this),4000);
				 			  }
				 			  else if (res.error == "email taken")
				 			  {
				 				  this.setState({password: '', showEmailTakenError: 'block', displaySignButtons: true});
				 				  setTimeout(function(){
				 				  	this.setState({showEmailTakenError: 'none'});
				 				  }.bind(this),4000);
				 			  }
				 			  else if (res.error == "email format")
				 			  {
				 				  this.setState({password: '', showEmailInvalidError: 'block', displaySignButtons: true});
				 				  setTimeout(function(){
				 				  	this.setState({showEmailInvalidError: 'none'});
				 				  }.bind(this),4000);
				 			  }
				 			  else if (res.error == "pswd blank")
				 			  {
				 				  this.setState({password: '', showPasswordError: 'block', displaySignButtons: true});
				 				  setTimeout(function(){
				 				  	this.setState({showPasswordError: 'none'});
				 				  }.bind(this),4000);
				 			  }
				 			  else if (res.error == "pswd short")
				 			  {
				 				  this.setState({password: '', showPasswordTooShortError: 'block', displaySignButtons: true});
				 				  setTimeout(function(){
				 				  	this.setState({showPasswordTooShortError: 'none'});
				 				  }.bind(this),4000);
				 			  }
													  
							
		                       }

		             } else {
		                        console.log("2.res: " + JSON.stringify(res));
		                        console.log("2.response: " + JSON.stringify(response.status));
		                     
		                       this.setState({displaySignButtons: true});
		                      

		             }



		        } catch(error) {
					
		               console.log("error: " + JSON.stringify(error));

		               this.setState({displaySignButtons: true});
		              
		        }
   }

  handleChangeAgreedTerms(event) {
	  
	                console.log("checked: " + JSON.stringify(this.state.agreedTerms));
					
			        setTimeout (function(){
			 		   console.log("2. checked: " + JSON.stringify(this.state.agreedTerms));
			        }.bind(this),2000);
	  
	             
				   
           // this.setState({password: event.target.value});



//	console.log("checked: " + JSON.stringify(this.state.agreedTerms))

		/*       this.setState({
		         agreedTerms: event.target.checked
		       });*/
  }

	/*SigninPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  login: PropTypes.func.isRequired
};
		*/

	    render() {

	      return (
	      <Translation>
	  	    { t =>
	  	         <div>

	  			   <BrowserView>

	  					  <div className="main_header">
	  					        <div className="main_header_inside">
	  					           <div className="main_header_inside_left">
	  					                           <a href="/">
	  					                                <img src={'https://s3.amazonaws.com/debyooinc20.img/debyoo-logo-w300.png'} alt="Debyoo Logo"/>
	  							           </a>
	  					            </div>
														
								 <div className="main_header_inside_left_2">
														   
								       <a href="/song_page" className={this.props.pathname === '/song_page' ? 'activeHeaderLink' : 'nonactiveHeaderLink'}>Song Page</a>
								       <a href="/about/dshop" className={this.props.pathname === '/about/dshop' ? 'activeHeaderLink' : 'nonactiveHeaderLink'}>DShop</a>
									<a href="/about/dchat" className={this.props.pathname === '/about/dchat' ? 'activeHeaderLink' : 'nonactiveHeaderLink'}>DChat</a>
								   
								</div>
														
	  					       </div>
	  					     </div>

           
		
		
		
		

	  					     <div className="signup_transp_wrapper">
	  						     <div className="sign_transp_inner">

	  							    <div style={{textAlign:'center', marginBottom: 20,minHeight:70}}>
	  									<img src={'https://s3.amazonaws.com/debyooinc20.img/debyoo_big_icon.png'} alt="Debyoo Icon" style={{width:70}}/>
	  							     </div>

	  								<div className="sign_title" style={{position: 'relative',width:370, minWidth:370}}>
										 
										         {t('signup')}

			                                                         <div style={{display: this.state.showTermsError, position: 'absolute',zIndex:3,top:-6,width:370, backgroundColor: '#CA1C01', borderRadius: 4}}>
									                   <div style={{padding:10, color: '#fff', fontSize: 14}}>{t('You_must_agree_to_the_terms')}</div>
						      			     	        </div>


							                             <Gradient style={{display: this.state.showNameError, position: 'absolute',zIndex:3,top:0,width:370}}
			         									   gradients={[
			         									   			['#CA1C01', '#BB1603'],
			         									   		]}
			         								           property="background"
			         								           duration={ 3000 }
			         								           angle="0deg"
			         									    className="buttonWhiteText">
			         										  {t('name_blank')}
							         				</Gradient>

							                               <Gradient style={{display: this.state.showEmailTakenError, position: 'absolute',zIndex:3,top:0,width:370}}
									                                gradients={[
									                                     ['#CA1C01', '#BB1603'],
									                                   ]}
									                                      property="background"
									                                      duration={ 3000 }
									                                      angle="0deg"
									                                 className="buttonWhiteText">
									                                 {t('email_taken')}
							                                </Gradient>

							                                <Gradient style={{display: this.state.showEmailInvalidError, position: 'absolute',zIndex:3,top:0,width:370}}
								                                   gradients={[
								                                       ['#CA1C01', '#BB1603'],
								                                     ]}
								                                        property="background"
								                                        duration={ 3000 }
								                                        angle="0deg"
								                                   className="buttonWhiteText">
								                                   {t('email_invalid')}
							                                  </Gradient>

							                                  <Gradient style={{display: this.state.showPasswordError, position: 'absolute',zIndex:3,top:0,width:370}}
								                                    gradients={[
								                                         ['#CA1C01', '#BB1603'],
								                                       ]}
								                                          property="background"
								                                          duration={ 3000 }
								                                          angle="0deg"
								                                     className="buttonWhiteText">
								                                     {t('password_blank')}
							                                    </Gradient>

							                                    <Gradient style={{display: this.state.showPasswordTooShortError, position: 'absolute',zIndex:3,top:0,width:370}}
							                                      gradients={[
							                                           ['#CA1C01', '#BB1603'],
							                                         ]}
							                                            property="background"
							                                            duration={ 3000 }
							                                            angle="0deg"
							                                       className="buttonWhiteText">
							                                       {t('password_short')}
							                                      </Gradient>

									 </div>
																   
									 <form onSubmit={this.onSubmit} noValidate="novalidate">

			  								 <input className="basic_ti_top"  onChange={this.handleChangeName} value={this.state.name} style={{width: '356px',padding: '6px'}} 
			  								        name="user[name]" placeholder={t('Full_name')} type="text" autoCapitalize="on" autoComplete="off" autoCorrect="off"/>

			  								 <input className="basic_ti_middle"  onChange={this.handleChangeEmail} value={this.state.email} style={{width: '356px',padding: '6px'}} 
			  								        name="user[email]" placeholder={'Email'} type="email" autoCapitalize="off" autoComplete="off" autoCorrect="off"/>

			  								   <input  className="basic_ti_bottom" name="user[password]" placeholder={t('Password')} onChange={this.handleChangePassword} value={this.state.password}
			  								        style={{width: '356px',padding: '6px'}}  type="password" autoCapitalize="off" autoComplete="off" autoCorrect="off"/>


		  								       {this.state.displaySignButtons &&
											     <button style={{background: 'rgba(0,0,0,0)', border: 'none', padding: 0}}>
													<Gradient style={{width:370, marginTop: 10}}
					  									   gradients={[
					  									   			['#1392E8', '#035598'],
					  									   		]}
					  								           property="background"
					  								           duration={ 3000 }
					  								           angle="0deg"
					  									    className="buttonWhiteText">
					  										  {t('signup')}
			  								              </Gradient>
											      </button>
											 }
											 
											 
								                     {!this.state.displaySignButtons &&
											<Gradient style={{width:370, marginTop: 10}}
			  									   gradients={[
			  									   			['#1392E8', '#035598'],
			  									   		]}
			  								           property="background"
			  								           duration={ 3000 }
			  								           angle="0deg"
			  									    className="buttonWhiteText">
								                             <div style={{paddingTop:3}}>
								  						 <Spinner color="#fff" size={14} speed={0.8}/>
								  				 </div>
								                      </Gradient>
								                    }

			  								    <div style={{width:370, textAlign:'left', marginTop: 10, color:'#fff', fontSize: 15}}>
												  <div style={{float: 'left', width:25,marginTop:-5, marginRight:7, marginLeft: 0}}>
												    <Checkbox id="termsCB" name="agreedTerms"
									                            type="default"
									                           defaultChecked={this.state.agreedTerms}
									                           onChange={this.handleChangeAgreedTerms} />
												  </div>
												  <div style={{float: 'left', width:20}}></div>
												  <div style={{float: 'left', width:320, marginLeft: 10}}>
													 {t('I_agree_to_debyoos')} <a href="/terms" style={{color:'#5dceee', fontSize: 15}}>{t('terms_short')}</a>  {t('and')} <a href="/eula" style={{color:'#5dceee', fontSize: 15}}>{t('eula_short')}</a> {t('of_debyoo_fr')}
											         </div>
												  <div style={{clear: 'both'}}></div>
											    </div>
											 
									   </form>


	  								    {this.state.displaySignButtons &&
										<a href="/signin">
										      <Gradient style={{width:370, marginTop: 20}}
		  									   gradients={[
		  									   			['#FAD741', '#F3BA21'],
		  									   		]}
		  								           property="background"
		  								           duration={ 3000 }
		  								           angle="0deg"
		  									    className="buttonBlackText">
		  										  {t('signin')}
		  								      </Gradient>
									      </a>
									  }





	  						      </div>
	  					      </div>


	  						<Fade {...fadeProperties}>

	  						      <div className="each-fade bg_jungle_day">
	  						        <div className="signin-image-container">
	  						            <img src={fadeImages[1]} />
	  						        </div>
	  						      </div>

	  						      <div className="each-fade bg_clean_mirror">
	  						        <div className="signin-image-container">
	  						          <img src={fadeImages[1]} />
	  						        </div>
	  						      </div>

	  						      <div className="each-fade bg_mole_hall">
	  						        <div className="signin-image-container">
	  						          <img src={fadeImages[1]} />
	  						        </div>
	  						      </div>

	  				      </Fade>

  			                   <div className="home_footer_right">
  			                          © {this.state.currentYear}  Debyoo&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;<a href="javascript:void(0)" onClick={() => this.setLocale("en")}>{t('English')}</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="javascript:void(0)" onClick={() => this.setLocale("fr")}>{t('French')}</a>&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;<a href="/our_mission">{t('Our_mission')}</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="/contact">{t('Contact')}</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="/privacy">{t('Privacy')}</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="/terms">{t('Terms')}</a>
  			                   </div>

	  			</BrowserView>


	             </div>
	  	 }

	        </Translation>
	      );
	   }


}

export default (withRouter(SignupPage));

//export default connect(null, { login })(SigninPage);
