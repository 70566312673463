import React,{ Component } from 'react';
import loadable from "@loadable/component";

import { BrowserRouter, Route, Link, Switch, withRouter, Redirect  } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation, Translation, } from 'react-i18next';
import PropTypes from 'prop-types';

import { sessionService } from 'redux-react-session';

import { Text, View } from 'react-native-web';




import DropdownMenu from "./components/header/DropdownMenu";
import DropdownMenuItem from "./components/header/DropdownMenuItem";



import setLanguage from "./actions/setLanguage";
import setUserConnection from "./actions/setUserConnection";


import PubSub from 'pubsub-js';

import {BrowserView,MobileView,TabletView,isBrowser,isMobileOnly, isTablet} from 'react-device-detect';



import { isLogin, profileExists, isReservedPath } from './utils';

import i18n from "./i18n";

import {generateIPForREST} from "./actions";



const HomePage = loadable(() => import("./components/pages/HomePage"));
const YInitJJoin = loadable(() => import("./components/pages/yinitj/YInitJJoin"));
const ErrorPageNotFound = loadable(() => import("./components/pages/error/ErrorPageNotFound"));

const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;

class App extends Component {

    constructor(props) {

          super(props);



   	 this.state = {
       displayVideoPop: false,
           videoID: null,
   	 };

     }


		 componentWillUnmount(){


          PubSub.unsubscribe(this.launchVideoPopVar);


   }





    componentDidMount() {

      document.addEventListener('mousedown', this.handleClickOutsideVideoPop);


		 this.launchVideoPopVar = PubSub.subscribe('launchVideoPop', this.onLaunchVideoPop.bind(this));

	   console.log("UA: " + navigator.userAgent)

		fetch('https://extreme-ip-lookup.com/json/')
		  	.then((response) => response.json())
		  	.then((responseData) => {
				 console.log("App. Store: " + JSON.stringify(responseData));

				  localStorage.setItem("geoLocation", responseData);

				  if (isLogin())
				  {
					  if (localStorage.getItem('language') === null)
					  {
						  this.getLanguageFromServer(responseData.countryCode);

					  }
					  else
					  {
						  var _lang = localStorage.getItem('language');
						  console.log("Got language from localStore: " + _lang);
					  	  i18n.changeLanguage(_lang);
					  }
				  }
				  else
				  {
				  	  this.props.setLanguage(responseData.countryCode);
					  this.props.setUserConnection({ip: responseData.query, connection_country: responseData.countryCode, connection_city: responseData.city})
				  }


				  // .country_code
		      })
		     .catch(function(error) {
			          console.log(error);

				   var _country_code  = "US", _lang = 'en';

  				  if (isLogin())
  				  {
  					  if (localStorage.getItem('language') === null)
  					  {

						   this.getLanguageFromServer(_country_code);
  					  }
  					  else
  					  {
  						  var _lang = localStorage.getItem('language');
  						  console.log("Got language from localStore: " + _lang);
  					  	  i18n.changeLanguage(_lang);
  					  }
  				  }
  				  else
  				  {
  				  	  this.props.setLanguage('US');
  				  }


		     }.bind(this));
		   /*.catch((error) => {

		   })
		   .done();*/
	/*	console.log("App. Store: " + JSON.stringify(this.props));

		    this.props.setBgAction("white");

			setTimeout (function(){
				console.log(">>>>2. App. Store: " + JSON.stringify(this.props));
			}.bind(this),3000);*/
    }


		handleClickOutsideVideoPop(event) {
      if (isBrowser)
      {
          setTimeout (function(){
            if (this.videoPopWrapperRef && this.videoPopWrapperRef.current !== null && !this.videoPopWrapperRef.current.contains(event.target)) {
              this.setState({
                displayVideoPop: false,
                videoID: null
              });
            }
          }.bind(this), 100);
     }

    }

    onLaunchVideoPop = (msg,data) => {

     // alert(data)

       this.setState({
         displayVideoPop: true,
         videoID: data
       });
    }


		showVideoPop() {
		 this.setState({
			 displayVideoPop: true
		 });
	 }

	 closeVideoPop() {


		 this.setState({
			 displayVideoPop: false,
       videoID: null
		 });
	 }


	getLanguageFromServer(_country_code) {
		//var ip = "https://www.debyoo.com/";
		var ip = generateIPForREST();

		var jwtToken = localStorage.getItem("jwtToken") || '';
		//  this.props.setLanguage(responseData.country_code);
		fetch(ip+'get_language_from_server_api', {
                              method: 'POST',
                              headers: {
                                'Accept': 'application/json',
				    'Authorization': jwtToken,
                                'Content-Type': 'application/json'
                              },
				   credentials: 'include',
                              body: JSON.stringify({
                              })
                      })
		  	.then((response) => response.json())
		  	.then((responseData) => {
				console.log("Got language from server: " + responseData.language);
				 i18n.changeLanguage(responseData.language);
	             })
	            .catch(function(error) {
				     this.props.setLanguage(_country_code);
		     }.bind(this));
	}










    render () {




	let store = this.props.store;
	const { history } = this.props;

	console.log("history: " + JSON.stringify(history))

        return (
	         <Translation>
			{
			        t =>
	           <BrowserRouter>


			           <div className="app_main_wrapper">



              {this.state.displayVideoPop  &&
                <div style={{position: 'fixed', width: '100%', height:  '100%', top:  0, left: 0, overflow: 'hidden', backgroundColor: '#fff', zIndex: 9999999999999999}}>

                   <div style={{position: 'relative', width: '100%', height:  '100%',backgroundColor: '#fff'}}>

                         <div onClick={this.closeVideoPop.bind(this)} style={{cursor: 'pointer',  position: 'absolute', zIndex: 99, right: '20px', top: '0px', color: '#999', fontSize: '70px', fontWeight: 'normal', fontFamily: 'Lato'}}>&times;</div>

                        <View style={{position: 'relative', width: '100%', height: '100%', justifyContent: 'center'}}>

                           {isBrowser &&
                              <div  ref={this.videoPopWrapperRef} style={{margin:'auto', background:  '#f1f1f1', width: (viewportHeight*0.7*16/9)+'px', height: (viewportHeight*0.7)+'px'}}>
       													 {this.state.videoID !== null &&
                                    <iframe width={viewportHeight*0.7*16/9} height={viewportHeight*0.7}
                                     src={'https://www.youtube.com/embed/'+this.state.videoID+'?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=1&autoplay=1'} frameborder="0" allowfullscreen></iframe>
                                }
       									     </div>
                           }

                           {isMobileOnly &&
                              <div  ref={this.videoPopWrapperRef} style={{margin:'auto', background:  '#f1f1f1', width: (viewportWidth*0.9)+'px', height: (viewportWidth*0.9*9/16)+'px'}}>
       													 {this.state.videoID !== null &&
                                    <iframe width={viewportWidth*0.9} height={viewportWidth*0.9*9/16}
                                     src={'https://www.youtube.com/embed/'+this.state.videoID+'?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=1&autoplay=1'} frameborder="0" allowfullscreen></iframe>
                                }
       									     </div>
                           }

                           {isTablet &&
                              <div  ref={this.videoPopWrapperRef} style={{margin:'auto', background:  '#f1f1f1', width: (viewportWidth*0.9)+'px', height: (viewportWidth*0.9*9/16)+'px'}}>
       													 {this.state.videoID !== null &&
                                    <iframe width={viewportWidth*0.9} height={viewportWidth*0.9*9/16}
                                     src={'https://www.youtube.com/embed/'+this.state.videoID+'?rel=0&modestbranding=1&autohide=1&showinfo=0&controls=1&autoplay=1'} frameborder="0" allowfullscreen></iframe>
                                }
       									     </div>
                           }

                        </View>

                   </div>

               </div>
             }



					        <Route path="/:id" exact render={props => (
							   isReservedPath(props.location.pathname)?
							  <HomePage store={this.props.store} />
					              : <ErrorPageNotFound store={this.props.store} path={props.location.pathname}/>
					        )} />

					        <Route path="/yinitj/:id" exact render={props => (
							   isReservedPath(props.location.pathname)?
							  <YInitJJoin store={this.props.store} />
					              : <ErrorPageNotFound store={this.props.store} path={props.location.pathname}/>
					        )} />


						   <Route path="/:id/:idd" exact render={props => (
							     props.location.pathname.indexOf('/yinitj/') > -1 ? null  :
							     isReservedPath(props.location.pathname)?
							      null
							      : <ErrorPageNotFound store={this.props.store} path={props.location.pathname}/>
					           )} />




					        <Route path="/" exact render={props => <HomePage store={this.props.store} />} />

					        <Route path="/yinitj" exact render={props => (
					               <Redirect to="/yinitj/join" />
					        )} />

				        </div>
				    </BrowserRouter>
	                    }
	           </Translation>
        );
    }
};


const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  setLanguage:  (payload) => dispatch(setLanguage(payload)),
  setUserConnection:  (payload) => dispatch(setUserConnection(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
