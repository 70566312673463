import React, { Component } from 'react';

import { observable, computed, reaction,action,decorate } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import { withTranslation, Translation, } from 'react-i18next';

import { connect } from "react-redux";



import {Link} from "react-router-dom";



import PubSub from 'pubsub-js';


//import MetaTags from 'react-meta-tags';
//import HeaderPC from "../header/HeaderPC";
import   FooterTablet from "../../footer/FooterTablet";

import i18n from "../../../i18n";




import { Fade } from 'react-slideshow-image';

const fadeProperties = {
  duration: 3500,
  transitionDuration: 500,
  infinite: true,
  arrows: false,
  indicators: false
}


const viewportWidth = window.innerWidth;
const viewportHeight = window.innerHeight;

//@inject("appStore")
//@observer
class HomePageTablet extends Component {

    constructor(props) {

          super(props);



       //   console.log(">>>  store:" + JSON.stringify(store))

		//  store.dispatch("Hello World")

   	 this.state = {
           backgroundColor: "black",
          activeColor: "info",
		 currentYear: new Date().getFullYear()
   	};

     }


	 componentWillMount(){
   	   document.body.style.backgroundImage = "#fff";

	   window.addEventListener("orientationchange", function() {
	   	// Announce the new orientation number
		   window.location.reload();
	   }, false);
	 }

	 componentWillUnmount(){
    	   document.body.style.backgroundImage = null;
	 }


    setLocale(lang) {

  	  console.log("Locale Lang:" +lang)
        //   this.props.appStore.locale = lang;

	 	  localStorage.setItem("language", lang);
	 	  i18n.changeLanguage(lang);
    }

    whatchFilm(t) {
       PubSub.publish('launchVideoPop', t);
    }




  render() {
	//console.log("isMobile:" + isMobile)

  //	const { t, i18n } = useTranslation();

  /*	   const changeLanguage = lng => {
  	      i18n.changeLanguage(lng);
  	};*/

    return (
    <Translation>
	    { t =>
	         <div>



							<div style={{position: 'absolute', zIndex: 3, top: '10px', right: '20px'}}>
			                                     {i18n.language == "fr" &&
								     <div  onClick={() => this.setLocale("en")} style={{cursor: 'pointer', float: 'right', fontSize: '15px', background: 'rgba(0,0,0,0)', borderRadius: '50%', width: '40px', lineHeight: '38px', height: '40px', textAlign: 'center', border: '1px solid #a3cdef', color: 'rgb(255,255,255)'}}>
								           EN
								     </div>
		                                            }
			                                     {i18n.language == "en" &&
								     <div  onClick={() => this.setLocale("en")} style={{cursor: 'pointer', float: 'right', fontSize: '15px', background: 'rgba(0,0,0,0)', borderRadius: '50%', width: '40px', lineHeight: '38px', height: '40px', textAlign: 'center', border: '1px solid rgba(255,255,255,1)', color: 'rgb(255,255,255)'}}>
								           EN
								     </div>
		                                            }
								  {i18n.language == "fr" &&
								     <div  onClick={() => this.setLocale("fr")} style={{cursor: 'pointer', float: 'right', marginRight: '10px', fontSize: '15px', background: 'rgba(0,0,0,0)', borderRadius: '50%', width: '40px', lineHeight: '38px', height: '40px', textAlign: 'center', border: '1px solid rgba(255,255,255)', color: 'rgb(56,9,67)'}}>
								          FR
								     </div>
								   }
								  {i18n.language == "en" &&
								     <div  onClick={() => this.setLocale("fr")} style={{cursor: 'pointer', float: 'right', marginRight: '10px', fontSize: '15px', background: 'rgba(0,0,0,0)', borderRadius: '50%', width: '40px', lineHeight: '38px', height: '40px', textAlign: 'center', border: '1px solid #a3cdef', color: 'rgb(255,255,255)'}}>
								          FR
								     </div>
								   }
								  <div style={{clear: 'both'}} />
							</div>






              <div  style={{position: 'relative', width: '100%', minHeight: '400px',  height: viewportHeight-80, overflow: 'hidden', marginTop: '0px', zIndex: 0, fontFamily: 'Roboto'}}>

                                      <div style={{width:'100%',height:'100%',background:'rgba(0,0,0,0.5)',
                                                  position:'absolute',left:'0px', margin:'0px', padding:'0px', top:'0px', zIndex: 2}}>
                                 			</div>
                                      <div style={{width:'100%',height:'100%',background:'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAACCAYAAABytg0kAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABZJREFUeNpi2r9//38gYGAEESAAEGAAasgJOgzOKCoAAAAASUVORK5CYII=)',
                                                  position:'absolute',left:'0px', margin:'0px', padding:'0px', top:'0px', zIndex: 1}}>
                                 			</div>
                                      <div style={{background: 'url(https://togotptogo4.s3.amazonaws.com/ezgif.com-optimize.gif) no-repeat center top',
                                                   backgroundSize: 'cover', height: '110%', }}>
                                     </div>

                                    <div style={{position: 'absolute', zIndex: 3, width: '100%', top: '150px', textAlign: 'center', color: '#fff',
                                                textShadow: '1px 1px 1px #000'}}>

                                                {i18n.language == "fr" &&
                                                  <div style={{position: 'relative', width: '80%', textAlign: 'center', fontSize: '60px', margin: 'auto'}}>
                                                    <div>Togo The Place To Go</div>
                                                    <div style={{fontSize: '22px', marginTop: '60px'}}>Narrateur</div>
                                                    <div style={{fontSize: '35px', marginTop: '5px'}}>Julio Teko</div>
                                                  </div>
                                                }
                                                {i18n.language == "en" &&
                                                  <div style={{position: 'relative', width: '80%', textAlign: 'center', fontSize: '60px', margin: 'auto'}}>
                                                     <div>Togo The Place To Go</div>
                                                    <div style={{fontSize: '22px', marginTop: '60px'}}>Narrator</div>
                                                    <div style={{fontSize: '35px', marginTop: '5px'}}>Julio Teko</div>
                                                  </div>
                                                }



                                                {i18n.language == "fr" &&
                                                     <a href={'https://www.debyoo.com'} target={'_blank'}>
                                                  <div  style={{cursor: 'pointer', position: 'relative', width: '350px',  height: '40px', lineHeight: '40px',
                                                               borderRadius: '30px', border: '2px solid #fff', background: 'rgba(0,0,0,0.4)',
                                                                textAlign: 'center', fontSize: '22px', margin: 'auto', marginTop: '80px', }}>
                                                        <div style={{float:'left', marginLeft: '22px', height: '40px', lineHeight: '40px', color: '#fff'}}>Regarder la vidéo</div>
                                                        <div style={{float:'right', marginRight: '5px', marginTop: '3px'}}><img src={'/img/swiper_next.png'} style={{width: '34px'}}/></div>
                                                        <div style={{clear:'both'}}></div>
                                                     </div>
                                                  </a>
                                                }
                                                {i18n.language == "en" &&
                                                     <a href={'https://www.debyoo.com'} target={'_blank'}>
                                                   <div   style={{cursor: 'pointer', position: 'relative', width: '220px',  height: '40px', lineHeight: '40px',
                                                               borderRadius: '30px', border: '2px solid #fff', background: 'rgba(0,0,0,0.4)',
                                                                textAlign: 'center', fontSize: '22px', margin: 'auto', marginTop: '80px', }}>
                                                                <div style={{float:'left', marginLeft: '22px', height: '40px', lineHeight: '40px', color: '#fff'}}>Watch the video</div>
                                                                <div style={{float:'right', marginRight: '5px', marginTop: '3px'}}><img src={'/img/swiper_next.png'} style={{width: '34px'}}/></div>
                                                                <div style={{clear:'both'}}></div>
                                                  </div>
                                                </a>
                                                }



                                                {i18n.language == "fr" &&
                                                  <div style={{position: 'relative', width: '80%', textAlign: 'center', fontSize: '40px', margin: 'auto', marginTop: '70px'}}>
                                                      <div style={{fontSize: '20px', marginBottom: '5px'}}>Première diffusion le</div>
                                                      <div>30.10.2020</div>
                                                  </div>
                                                }
                                                {i18n.language == "en" &&
                                                  <div style={{position: 'relative', width: '80%', textAlign: 'center', fontSize: '40px', margin: 'auto', marginTop: '70px'}}>
                                                       <div style={{fontSize: '20px', marginBottom: '5px'}}>Release date</div>
                                                       <div>10.30.2020</div>
                                                  </div>
                                                }

                                   </div>


                                   <div style={{position: 'absolute', zIndex: 3, width: '90%', left: '5%', bottom: '5px', textAlign: 'center'}}>


                                       <div style={{float: 'left', marginLeft: '30px'}}><img src={'https://togotptogo4.s3.amazonaws.com/debyoo_originals_white_shadow.png'} style={{height: '60px'}} /></div>

                                       <div style={{float: 'left', marginLeft: '70px', marginTop: '5px'}}><img src={'https://togotptogo4.s3.amazonaws.com/TogotpTogo_Logo_white_flat.png'} style={{height: '50px'}} /></div>

                                      <div style={{float: 'right', marginRight: '30px', marginTop: '25px'}}><img src={'https://togotptogo4.s3.amazonaws.com/ahoefilms_white_shadow.png'} style={{height: '32px'}} /></div>

                                      <div style={{float: 'right', marginRight: '70px', marginTop: '25px'}}><img src={'https://togotptogo4.s3.amazonaws.com/telioprod_white_shadow.png'} style={{height: '32px'}} /></div>
                                   </div>

              </div>





               <div   style={{background: 'url(https://togotptogo4.s3.amazonaws.com/togotptogo.jpg) no-repeat center top',
												  backgroundSize: 'cover', position: 'relative', width: '100%', minHeight: '600px',  height: viewportHeight*0.9, overflow: 'hidden', marginTop: '0px', zIndex: 0}}>
									 {viewportWidth > viewportHeight &&
									     <div style={{position: 'absolute', zIndex: 3, bottom: '-15%', left: '-15%', width: '100%'}}>
										   <img src={'https://togotptogo4.s3.amazonaws.com/togotptogo_logo_transp_grad_2.png'} style={{width: '70%'}} />
									     </div>
									  }
 									 {viewportWidth < viewportHeight &&
 									     <div style={{position: 'absolute', zIndex: 3, bottom: '-5%', left: '-15%', width: '100%'}}>
 										   <img src={'https://togotptogo4.s3.amazonaws.com/togotptogo_logo_transp_grad_2.png'} style={{width: '70%'}} />
 									     </div>
 									  }
							</div>

							<div style={{width: '100%', backgroundColor: '#fff'}}>
								 <div style={{margin: 'auto', height: '20px'}} />
							         <div style={{position: 'relative', width: '80%', margin: 'auto', textAlign: 'center', backgroundColor: '#fff', paddingTop: '20px', paddingBottom: '20px'}}>
									    <div style={{position: 'absolute', zIndex: 3, top: 0, left: 0, width: '60px'}}>
									          <img src={'https://togotptogo4.s3.amazonaws.com/square_border_togo_tl.png'} style={{width: '60px'}} />
									    </div>
									    <div>
		  							      {i18n.language == "fr" &&
		  									<img src={'https://togotptogo4.s3.amazonaws.com/authenticgenuine_fr.png'} alt="Authentique et vrai" style={{width: '98%'}} />
		  								}
		  							      {i18n.language == "en" &&
		  									<img src={'https://togotptogo4.s3.amazonaws.com/authenticgenuine_en.png'} alt="Authentic and genuine" style={{width: '98%'}} />
		  								}
									    </div>
									    <div style={{position: 'absolute', zIndex: 3, bottom: 0, right: 0, width: '60px'}}>
									          <img src={'https://togotptogo4.s3.amazonaws.com/square_border_togo_br.png'} style={{width: '60px'}} />
									    </div>
								  </div>
								  <div style={{margin: 'auto', height: '20px'}} />
							</div>



  			                            {i18n.language == "fr" &&
							     <div   style={{background: 'url(https://togotptogo4.s3.amazonaws.com/tg_emotional_factors_fr.jpg) no-repeat center top',
  												  backgroundSize: 'cover', position: 'relative', width: '100%', minHeight: '500px',  height: viewportHeight*0.4, overflow: 'hidden', marginTop: '0px', zIndex: 0}}>
  									  <div style={{position: 'absolute', zIndex: 3, top: '31.8%', left: '10px'}}>
  										   <img src={'https://togotptogo4.s3.amazonaws.com/dynamicdiverse_fr.png'} style={{width: '300px'}} />
  									  </div>
  							   </div>
							}
			                            {i18n.language == "en" &&
							     <div   style={{background: 'url(https://togotptogo4.s3.amazonaws.com/tg_emotional_factors_en.jpg) no-repeat center top',
												  backgroundSize: 'cover', position: 'relative', width: '100%', minHeight: '500px',  height: viewportHeight*0.9, overflow: 'hidden', marginTop: '0px', zIndex: 0}}>
									  <div style={{position: 'absolute', zIndex: 3, top: '31.8%', left: '10px'}}>
										   <img src={'https://togotptogo4.s3.amazonaws.com/dynamicdiverse_en.png'} style={{width: '300px'}} />
									  </div>
							   </div>
							}




							<div style={{width: '100%', backgroundColor: '#fff'}}>
								 <div style={{margin: 'auto', height: '20px'}} />
							         <div style={{position: 'relative', width: '80%', margin: 'auto', textAlign: 'center', backgroundColor: '#fff', paddingTop: '20px', paddingBottom: '20px'}}>
									    <div style={{position: 'absolute', zIndex: 3, top: 0, left: 0, width: '60px'}}>
									          <img src={'https://togotptogo4.s3.amazonaws.com/square_border_togo_tl.png'} style={{width: '60px'}} />
									    </div>
									    <div>
		  							      {i18n.language == "fr" &&
		  									<img src={'https://togotptogo4.s3.amazonaws.com/creative_nation_fr.png'} alt="La nation créative" style={{width: '98%'}} />
		  								}
		  							      {i18n.language == "en" &&
		  									<img src={'https://togotptogo4.s3.amazonaws.com/creative_nation_en.png'} alt="Creative nation" style={{width: '98%'}} />
		  								}
									    </div>
									    <div style={{position: 'absolute', zIndex: 3, bottom: 0, right: 0, width: '60px'}}>
									          <img src={'https://togotptogo4.s3.amazonaws.com/square_border_togo_br.png'} style={{width: '60px'}} />
									    </div>
								  </div>
								  <div style={{margin: 'auto', height: '20px'}} />
							</div>





  							<div style={{width: '100%', backgroundColor: '#fff'}}>

  								   <div style={{margin: 'auto', height: '60px'}} />

								   {1==2 && <div>
  								    <div style={{position: 'relative', width: '80%', margin: 'auto', textAlign: 'center', fontWeight: 'bold', fontSize: '28px', color: '#111'}}>
    		  							      {i18n.language == "fr" &&
  										    <div>
    		  								        <div>Forger des liens entre la Jeunesse, la Société Civile, le Secteur Privé et le Gouvernement.</div>
  										     </div>
    		  								}
    		  							      {i18n.language == "en" &&
  										   <div>
    		  								      <div>Linking Youth, Civil Society, Private Sector and Government.</div>
  										   </div>
    		  								}
  								   </div>

  								   <div style={{margin: 'auto', height: '40px'}} />
								  </div>}

  								    <a href={'/yinitj/join'}>
  								       <div style={{position: 'relative', float:'left', width: (viewportWidth/2)-2, backgroundColor: '#111', height: '350px',
										            background: 'url(https://yinitj5.s3.amazonaws.com/youthinitj_togo.jpg) no-repeat center center',   backgroundSize: 'cover',}}>
  										    <div style={{position: 'absolute', zIndex: 3, width: '100%',  top: '33%', textAlign: 'center', fontSize: '25px', color: '#fff'}}>
  										             <div>
  										                   <img src={'https://yinitj5.s3.amazonaws.com/yinitj_logo_transp.png'} style={{width: '250px'}}/>
  												</div>
  				  							      <div>TOGO</div>
  										   </div>

  										 <div style={{cursor:'pointer', position: 'absolute', zIndex: 3, width: '300px', height: '46px', lineHeight: '46px', bottom: '20px', left: '50%', marginLeft: '-150px',
  												 textAlign: 'center', fontSize: '20px', color: '#67bbff', border: '1px solid #67bbff', borderRadius: '23px'}}>
  														   {t('Join_the_community')}&nbsp;&nbsp;»
  										   </div>
  								      </div>
  								   </a>
  							          <div style={{position: 'relative', float:'right', width:  (viewportWidth/2)-2, backgroundColor: '#fafafa', height: '350px'}}>
  									         <Fade {...fadeProperties}>
  										       <div className="each-fade bg_jungle_day" style={{width:  (viewportWidth/2)-2, height: '350px'}}></div>
  										        <div className="each-fade bg_clean_mirror"  style={{width:  (viewportWidth/2)-2, height: '350px'}}></div>
  										        <div className="each-fade bg_mole_hall" style={{width:  (viewportWidth/2)-2, height: '350px'}}></div>
  									          </Fade>
  									         <div style={{display: 'none', position: 'absolute', zIndex: 3, width: '100%',  top: '45%', textAlign: 'center', fontSize: '25px',  fontWeight: 'bold', color: '#000'}}>
  				  							      {i18n.language == "fr" &&
  				  								     <div>Société Civile</div>
  				  								}
  				  							      {i18n.language == "en" &&
  				  								   <div>Civil Society</div>
  				  								}
  										   </div>
  								   </div>
  								   <div className="clear-both" style={{height: '4px',background: '#fff', }}/>
								   {1==2 && <div>
  							          <div style={{position: 'relative', float:'left', width:  (viewportWidth/2)-2, backgroundColor: '#fafafa', height: '350px'}}>
  									    <Fade {...fadeProperties}>
  										 <div className="each-fade bg_jungle_day" style={{width:  (viewportWidth/2)-2, height: '350px'}}></div>
  										  <div className="each-fade bg_clean_mirror"  style={{width:  (viewportWidth/2)-2, height: '350px'}}></div>
  										   <div className="each-fade bg_mole_hall" style={{width:  (viewportWidth/2)-2, height: '350px'}}></div>
  									   </Fade>
  									    <div style={{position: 'absolute', zIndex: 3, width: '100%',  top: '45%', textAlign: 'center', fontSize: '25px',  fontWeight: 'bold', color: '#000'}}>
  			  							      {i18n.language == "fr" &&
  			  								     <div>Secteur Privé</div>
  			  								}
  			  							      {i18n.language == "en" &&
  			  								   <div>Private Sector</div>
  			  								}
  									   </div>
  								   </div>
  							          <div style={{position: 'relative', float:'right', width:  (viewportWidth/2)-2, backgroundColor: '#fafafa', height: '350px'}}>
  									    <Fade {...fadeProperties}>
  										 <div className="each-fade bg_jungle_day" style={{width:  (viewportWidth/2)-2, height: '350px'}}></div>
  										  <div className="each-fade bg_clean_mirror"  style={{width:  (viewportWidth/2)-2, height: '350px'}}></div>
  										   <div className="each-fade bg_mole_hall" style={{width:  (viewportWidth/2)-2, height: '350px'}}></div>
  									   </Fade>
  									    <div style={{position: 'absolute', zIndex: 3, width: '100%',  top: '45%', textAlign: 'center', fontSize: '25px',  fontWeight: 'bold', color: '#000'}}>
  			  							      {i18n.language == "fr" &&
  			  								     <div>Gouvernement</div>
  			  								}
  			  							      {i18n.language == "en" &&
  			  								   <div>Government</div>
  			  								}
  									   </div>
  								   </div>
  								  <div className="clear-both" style={{height: '10px',background: '#fff', }}/>

  								   <div style={{margin: 'auto', height: '40px'}} />

  								    <div style={{position: 'relative', width: '80%', margin: 'auto', textAlign: 'center', fontWeight: 'bold', fontSize: '28px', color: '#111'}}>
    		  							      {i18n.language == "fr" &&
  										    <div>
  										         <div style={{marginTop: '10px'}}>Et travailler efficacement ensemble pour faire du</div>
  										     </div>
    		  								}
    		  							      {i18n.language == "en" &&
  										   <div>
  										      <div  style={{marginTop: '10px'}}>And working together efficiently to make</div>
  										   </div>
    		  								}
  										<div style={{marginTop: '10px', fontSize: '38px'}}>Togo The Place To Go</div>
  								   </div>
								   </div>}

  								   <div style={{margin: 'auto', height: '40px'}} />
  							</div>




						          <div className="clear-both" style={{height: '50px',background: '#fff', }}/>


							 <FooterTablet />



			        </div>
	  }
	 </Translation>
    )
  }
}



const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(HomePageTablet);
