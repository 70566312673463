
import React, { Component } from "react";
import PropTypes from 'prop-types';

import ReactDOM from 'react-dom';

import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

class DropdownMenu extends Component {
    propTypes: {
      isOpen: PropTypes.bool.isRequired,
      forceCloseFunction: PropTypes.func.isRequired,
      toggle: PropTypes.node.isRequired,
      direction: 'right',
      className: PropTypes.string,
      component: 'div'
    }

  /*  getDefaultProps() {
      return {
        direction: 'center',
        className: '',
        component: 'div'
      };
    }*/
	  
	        componentDidMount() {
	             window.addEventListener('click', this.handleClickOutside);
	        }

	        componentWillUnmount()  {

	          window.removeEventListener('click', this.handleClickOutside);
	       }
		   

    /* Only have the click events enabled when the menu is open */
    componentDidUpdate(prevProps, prevState) {
      if(this.props.isOpen && !prevProps.isOpen) {
        window.addEventListener('click', this.handleClickOutside);
      } else if(!this.props.isOpen && prevProps.isOpen) {
        window.removeEventListener('click', this.handleClickOutside);
      }   
    }

    /* If clicked element is not in the dropdown menu children, close menu */
    handleClickOutside = (e) =>   {
    //  var children = this.getDOMNode().getElementsByTagName('*');
	  
		   var children = ReactDOM.findDOMNode(this).getElementsByTagName('*');
	         for(var x in children) {
	             if(children[x] == e.target) { return; }
	        }

	         this.props.forceCloseFunction(e);
	 
    }

    handleKeyDown(e) {
      var key = e.which || e.keyCode;
      if(key !== 9) { // tab
        return;
      }

      var items = this.getDOMNode().querySelectorAll('button,a');
      var id = e.shiftKey ? 1 : items.length - 1;
      if(e.target == items[id]) {
        this.props.forceCloseFunction(e);
      }
    }


    render() {
      var items = this.props.isOpen ? this.props.children : null;
 
      return (
        <div className={'dd-menu' + (this.props.className ? ' ' + this.props.className : '')}>
          {this.props.toggle}
          <CSSTransitionGroup transitionName={'grow-from-' + this.props.direction} component="div"
                            className="dd-menu-items" onKeyDown={this.handleKeyDown}
				transitionEnterTimeout={500}
				 transitionLeaveTimeout={300}>
            {items}
          </CSSTransitionGroup>
        </div>
      );
    } 
}

export default DropdownMenu;
