import i18n from "../i18n";

const setLanguage = (payload) => {
  console.log("Country changed: " + payload) ;
  
  var language = setLanguageFromCountry(payload);
   localStorage.setItem("language", language);
   
    i18n.changeLanguage(language);
   
   console.log("Country changed >> Language : " + language) ;
   
  
   
  return {
    type: "countryChange",
    language
  }
}
export default setLanguage;



function setLanguageFromCountry (country) {
			    if (country == "ES" || country == "BO" || country == "CO" || country == "CR" || country == "CU" || country == "DO" || country == "EC" ||
			       country == "GT" || country == "HN" || country == "PA" || country == "PE" || country == "PY" || country == "VE" || country == "UY" ||
			       country == "NI" || country == "CL" || country == "AR" || country == "MX" || country == "PR")
			    {
					var res = "en"; // es
			     }
			     else if (country == "PT" || country == "BR" || country == "AO" || country == "CV" || country == "MZ" || country == "ST" || country == "MO" || country == "TL" || country == "GW")
			   {
				   var res = "en"; //pt
			    }
			   else if (country == "DE" || country == "AT")
			   {
				  var  res = "en";  // de
			   }
			   else if (country == "IN")
			   {
			       var res = "en"; // hi
			   }
			    else if (country == "MY")
			     {
					 var res = "en"; // ms
				}
			    else if (country == "GR" || country == "CY")
			    {
					var res = "en"; //el
			  }
			    else if (country == "RU" || country == "UA")
			   {
			       var res = "en"; //ru
			   }
			    else if (country == "AR" || country == "BO" || country == "CL" || country == "CO" || country == "CR" ||
			         country == "CU" || country == "DO" || country == "EC" || country == "ES" || country == "GQ" ||
			         country == "GT" || country == "HN" || country == "MX" || country == "NI" || country == "PA" ||
			         country == "PE" || country == "PR" || country == "PY" || country == "SV" || country == "UY")
			   {
				   var res = "en"; //es
			   }
			     else if (country == "SE")
			     {
			           var res = "en" //se" Svenska Swedish
				}
			    else if (country == "NL")
			    {
					var res = "en"; //nl" Nederlands Dutch
			    }
			    else if (country == "PL")
			     {
					 var res = "en"; //pl" Polski  Polish
				}
			     else if (country == "RO")
			     {
					 var res = "en"; // ro
			   }
			    else if (country == "DZ" || country == "MA" || country == "TN")
			   {
				   var res = "fr";
			   }
			   else if (country == "AE" || country == "BH" || country == "LB" || country == "EG" || country == "LY" ||
			         country == "IQ" || country == "JO" || country == "MR" || country == "KW" || country == "OM" ||
			         country == "PS" || country == "QA" || country == "SA" || country == "SD" || country == "SO" ||
			         country == "SY" || country == "YE")
			   {
				   var res = "en"; // ar
			   }
			   else if (country == "NL")
			  {
				  var res = "en"; //nl
			   }
			    else if (country == "FR" || country == "BE" || country == "MC" || country == "MQ" || country == "GP" || country == "PF" || country == "RE" || country == "TF" ||
			         country == "BJ" || country == "BF" || country == "CD" || country == "CG" || country == "CI" || country == "GA" || country == "GN" || country == "ML" || country == "NE" || country == "SN" || country == "TG" ||
			         country == "BI" || country == "CM" || country == "CF" || country == "DJ" || country == "GQ" || country == "HT" || country == "LU" || country == "MG" || country == "MR" || country == "MU" || country == "RW" || country == "SC" || country == "TD")
			    {
					var res = "fr";
			   }
			    else
			    {
					var res = "en";
			    }



				return res;

}