export default function userconnectionReducer (state = {} , action)  {
  switch (action.type) {
    case "userconnectionChange":
      return {
        ...state,
        userConnection: action.payload
      };
    default:
      return state;
  }
};