import React from "react";
//import PropTypes from "prop-types";
//import { connect } from "react-redux";

import { Link } from "react-router-dom";

import Spinner from 'react-activity/lib/Spinner';
import 'react-activity/lib/Spinner/Spinner.css';

import { inject, observer, Provider } from "mobx-react";
import { withTranslation, Translation, } from 'react-i18next';
import {BrowserView,MobileView,TabletView,} from 'react-device-detect';

import { Gradient } from 'react-gradient';

import { sessionService } from 'redux-react-session';

import {generateIPForREST} from "../../../actions";

import { Fade } from 'react-slideshow-image';

const fadeImages = [
  'https://s3.amazonaws.com/debyooinc20.img/home/home_img_stories.png',
  'https://s3.amazonaws.com/debyooinc20.img/home/Publish_SP4_06.png',
  'https://s3.amazonaws.com/debyooinc20.img/home/home_img_feels4.png'
];

const fadeProperties = {
  duration: 3500,
  transitionDuration: 500,
  infinite: true,
  arrows: false,
  indicators: false
}

//@inject("appStore")
class ResetpasswordPage extends React.Component {

    constructor(props) {

          super(props);



   	 this.state = {
		 email: '',
		 password: '',
		 pcode: '',
		 new_password: '',
		 reset_password_token: '',
		 showCodePopMessager: 'block',
              showPasswordError: 'none',
              showPasswordSuccess: 'none',
	      showEmailInvalidError: 'none',
		 showPasswordCodeError: 'none',
		 showPasswordError: 'none', 
		 showPasswordTooShortError: 'none',
		 displayPCodeVerif: false,
		 displayPCodeResend: false,  
              displaySignButtons: true,
		 displayNewPasswordWrapper: false,
		 displayPCodeWrapper: false,
		 displayNewPassword: false, 
		 currentYear: new Date().getFullYear()
   	  };

	   this.handleChangeEmail = this.handleChangeEmail.bind(this);
          this.handleChangePCode = this.handleChangePCode.bind(this);
	   this.onResendPCode = this.onResendPCode.bind(this);
	   this.handleChangeNewPassword = this.handleChangeNewPassword.bind(this);
	 // this.handleOnInput = this.handleOnInput.bind(this);
    	   this.onSubmit = this.onSubmit.bind(this);
	   this.onSubmitPCode = this.onSubmitPCode.bind(this);	   
	   this.onSubmitNewPassword  = this.onSubmitNewPassword.bind(this);


     }
	 
     componentWillMount() {
	   document.body.style.backgroundColor = "#fff";
     }
	 
	 componentWillUnmount(){
    	   document.body.style.backgroundColor = null;
	 }


    setLocale(lang) {

  	  console.log("Locale Lang:" +lang)
           this.props.appStore.locale = lang;
    }
	
	
 /* submit = data =>
    this.props.login(data).then(() => this.props.history.push("/dashboard"));
*/


    isEmail(email) {
       return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(email);
    }
	
  
	
    async onResendPCode(event) {
		 event.preventDefault();
		 
             this.setState({
                 displaySignButtons: false,
                 showCodePopMessager: 'none',
	          showPasswordCodeError: 'none',
	          displayPCodeVerif: false,
	          displayPCodeResend: true,  
		   displayNewPassword: false
             });
			
	     try {
		         var ip = generateIPForREST();
		   
		          let response = await fetch(ip+'reset_pswd_api', {
		                                 method: 'POST',
		                                 headers: {
		                                   'Accept': 'application/json',
		                                   'Content-Type': 'application/json',
		                                 },
		                                 body: JSON.stringify({
		                                   user:{
		                                     email: this.state.email
		                                   }
		                                 })
		                               });
		         let res = await response.json();
				 
			  if (response.status >= 200 && response.status < 300) {
				  
				                 if (res.status == "200")
				 		  {
				 			 this.setState({
								 displayNewPasswordWrapper: false,
								 displayPCodeWrapper: true,
				 				 showCodePopMessager: 'block', 
				 				 displayPCodeVerif: false,
				 				 displayPCodeResend: false,  
				 		              displaySignButtons: true,
								displayNewPassword: false
				 			 });
				 		  }
				 		  else
				 		  {
			 	   			 this.setState({
								 displayNewPasswordWrapper: false,
								 displayPCodeWrapper: true,
			 	   				 showCodePopMessager: 'none',
			 	   				 displayPCodeVerif: false,
			 	   				 displayPCodeResend: false,  
			 	   		              displaySignButtons: true,
								displayNewPassword: false
			 	   			 });
				 		  }
			  }
			  else 
			  {
	   			 this.setState({
					 displayNewPasswordWrapper: false,
					 displayPCodeWrapper: true,
	   				 showCodePopMessager: 'none',
	   				 displayPCodeVerif: false,
	   				 displayPCodeResend: false,  
	   		              displaySignButtons: true,
					displayNewPassword: false
	   			 });
			  }
				 
	       } catch(error) {
  			 this.setState({
				 displayNewPasswordWrapper: false,
				 displayPCodeWrapper: true,
  				 showCodePopMessager: 'none',
  				 displayPCodeVerif: false,
  				 displayPCodeResend: false,  
  		              displaySignButtons: true,
				displayNewPassword: false
  			 });
	       }
			
			
			
	     
		
	}
	
   async onSubmitPCode(event) {
          event.preventDefault();
	
          this.setState({
              displaySignButtons: false,
	       showCodePopMessager: 'none',
		showPasswordCodeError: 'none',
		 displayPCodeVerif: true,
		 displayPCodeResend: false,  
		 displayNewPassword: false
          });	
		  
          try {
                       console.log("submit");

                       //  var ip = 'https://www.debyoo.com/';
	                var ip = generateIPForREST();
				  
				  
		          let response = await fetch(ip+'verify_pswd_code_api', {
		                                 method: 'POST',
		                                 headers: {
		                                   'Accept': 'application/json',
		                                   'Content-Type': 'application/json',
		                                 },
		                                 body: JSON.stringify({
		                                   user:{
							  pcode: this.state.pcode,
		                                     email: this.state.email
		                                   }
		                                 })
		                               });
		         let res = await response.json();
				 
			 if (response.status >= 200 && response.status < 300) {
				 
				  if (res.status == "200")
				 {
					 console.log(">>> reset_password_token: " + res.reset_password_token);
					 
					 this.setState({
						 reset_password_token: res.reset_password_token,
						 displayPCodeWrapper: false,
						 displayNewPasswordWrapper: true,
						 showPasswordCodeError: 'none',
						 displayPCodeVerif: false,
						 displayPCodeResend: false,  
				              displaySignButtons: true,
						displayNewPassword: false
					 });
				 }
				 else
				 {
					 this.setState({
						 showPasswordCodeError: 'block',
						 displayPCodeVerif: false,
						 displayPCodeResend: false,  
				              displaySignButtons: true,
						displayNewPassword: false
					 });
				 }
			 }
			 else
			 {
				 this.setState({
					 showPasswordCodeError: 'block',
					 displayPCodeVerif: false,
					 displayPCodeResend: false,  
			              displaySignButtons: true,
					displayNewPassword: false
				 });
			 }
		
	    } catch(error) {	
			
			 this.setState({
				 showPasswordCodeError: 'block',
				 displayPCodeVerif: false,
				 displayPCodeResend: false,  
		              displaySignButtons: true,
				displayNewPassword: false
			 });
	    }
		  
	
   }
   
  async  onSubmitNewPassword(event) {
	      event.preventDefault();
		  
          this.setState({
              displaySignButtons: false,
	       showCodePopMessager: 'none',
		showPasswordCodeError: 'none',
		 displayPCodeVerif: false,
		 displayPCodeResend: false,  
		 displayNewPassword: true
          });
		  
		  var  isValid = true;
		  var isPasswordValid = this.state.new_password.length > 0 ? true : false;
		  isValid = isValid && isPasswordValid;
		  
  		if (!isValid)
  		{
  		            this.setState({
  				  showPasswordError: 'block',
  				 displaySignButtons: true
  		           });
		   
  			       setTimeout (function(){
  			               this.setState({
  			   		     showPasswordError: 'none'
  			              });
  			       }.bind(this),3000);
  		}
		else 
		{
		          var isPasswordNotShort = this.state.new_password.length > 5 ? true : false;
		          isValid = isValid && isPasswordNotShort;
				  
  		  		if (!isValid)
  		  		{
  		  		            this.setState({
  		  				  showPasswordTooShortError: 'block',
  		  				 displaySignButtons: true
  		  		           });
		   
  		  			       setTimeout (function(){
  		  			               this.setState({
  		  			   		     showPasswordTooShortError: 'none'
  		  			              });
  		  			       }.bind(this),3000);
  		  		}
				else
				{
					this.onConfirmSubmitNewPassword();
				}
		}
   }
   
   
   async onConfirmSubmitNewPassword() {
   	      try {
			         var ip = generateIPForREST();
				 
			          let response = await fetch(ip+'users/password.json', {
			                                 method: 'POST',
			                                 headers: {
			                                   'Accept': 'application/json',
			                                   'Content-Type': 'application/json',
			                                 },
			                                 body: JSON.stringify({
                                                        reset_password_token: this.state.reset_password_token,
				                           typef: 'api',
			                                   user:{
	                                                      reset_password_token: this.state.reset_password_token,
								     password: this.state.new_password,
								     password_confirmation: this.state.new_password,
								     email: this.state.email
			                                   }
			                                 })
			                               });
			         let res = await response.json();
			 
				 if (response.status >= 200 && response.status < 300) {
			 
					  if (res.status == "200")
					 {
						  console.log(">>> Got new jwtToken: " + res.jwt_token);
						  
						 localStorage.setItem("jwtToken", res.jwt_token);
						 
						 this.setState({
							 showPasswordCodeError: 'none',
							 displayPCodeVerif: false,
							 displayPCodeResend: false,  
					              displaySignButtons: false,
							displayNewPassword: false
						 });
						 
					      sessionService.saveSession({token: res.jwt_token, authenticated: true })
					         .then(() => {
					           sessionService.saveUser(res)
					           .then(() => {
				                    
									
							   window.location.href = "/home";
									
									
					           }).catch(err => console.error(err));
					         }).catch(err => console.error(err));
						 
					 }
					 else
					 {
						 this.setState({
							 showPasswordCodeError: 'block',
							 displayPCodeVerif: false,
							 displayPCodeResend: false,  
					              displaySignButtons: true,
							displayNewPassword: false
						 });
					 }
				 }
				 else
				 {
					 this.setState({
						 showPasswordCodeError: 'block',
						 displayPCodeVerif: false,
						 displayPCodeResend: false,  
				              displaySignButtons: true,
						displayNewPassword: false
					 });
				 }
		 
		} catch(error) {
			
		}
   }


   onSubmit(event) {
          event.preventDefault();


          this.setState({
            displaySignButtons: false
          });
		  
	   var isValid =  true;
	   var isEmailValid = this.isEmail(this.state.email);
	   isValid = isValid && isEmailValid;
	   
 		if (!isValid)
 		{
                    this.setState({showEmailInvalidError: 'block', displaySignButtons: true});
                    setTimeout(function(){
                          this.setState({showEmailInvalidError: 'none'});
                    }.bind(this),4000);
 		}
		else 
		{
			this.confirmOnSubmit();
		}
		
		
	
	   
   }


    async confirmOnSubmit() {	
		
           try {
                console.log("submit");

              //  var ip = 'https://www.debyoo.com/';
		    var ip = generateIPForREST();

               //  var ip = 'http://localhost:3000/';

                var jwtToken = localStorage.getItem("jwtToken") || '';

                console.log(">>> jwtToken: " + jwtToken);

                let response = await fetch(ip+'reset_pswd_api', {
                                        method: 'POST',
                                        headers: {
                                          'Accept': 'application/json',
                                          'Content-Type': 'application/json',
                                        },
                                        body: JSON.stringify({
                                          user:{
                                            email: this.state.email
                                          }
                                        })
                                      });
                let res = await response.json();

                if (response.status >= 200 && response.status < 300) {

                          if (res.status == "100")
                          {
                                 console.log(">>> 100 HERE >>> " + JSON.stringify(res));

                                /* this.reactivateStep = 1;
                                  this.responseData = res;

                                 this.setState({
                                   virtualjobvar: 'a',
                                   global_account_active_status: res.global_account_active_status
                                 });*/
                          }
                           else if (res.status == "200")
                           {

                                  console.log(">>> 200 HERE >>> " + JSON.stringify(res));
					  console.log(">>> email : " + this.state.email);

                                  this.setState({displaySignButtons: true, displayPCodeWrapper: true});

                                /*  store.setSelectedLocalePage('PasswordCode');
                                  this.props.navigation.navigate('PasswordCode', {email: this.state.email});

                                        this.setState({showProgress: false, showProgressWrapper: 'none', showPasswordButton: 'flex', showSignInButton: 'flex',
                                    showSignUpButton: 'flex',});


                                  this.setState({email: ''});*/
                          }
                          else
                          {
                             console.log(">>> Other HERE >>> " + JSON.stringify(res));
                             console.log(">>> response >>> " + JSON.stringify(response));
                            this.setState({showPasswordError: 'block', displaySignButtons: true});
                            setTimeout(function(){
                              this.setState({showPasswordError: 'none'});
                            }.bind(this),4000);

                            //this.setState({showProgress: false, showProgressWrapper: 'none', showPasswordButton: 'flex', showSignInButton: 'flex',
                             //  showSignUpButton: 'flex',});
                          }

                } else {
                           console.log("2.res: " + JSON.stringify(res));
                           console.log("2.response: " + JSON.stringify(response.status));
                                //Handle error
                               // let error = res;
                               // throw error;
                          this.setState({showPasswordError: 'block', displaySignButtons: true});
                          setTimeout(function(){
                            this.setState({showPasswordError: 'none'});
                          }.bind(this),4000);

                         // this.setState({showProgress: false, showProgressWrapper: 'none', showPasswordButton: 'flex', showSignInButton: 'flex',
                          //showSignUpButton: 'flex',});
                }



           } catch(error) {
                  console.log("error: " + JSON.stringify(error));

                  this.setState({showPasswordError: 'block', displaySignButtons: true});
                  setTimeout(function(){
                    this.setState({showPasswordError: 'none'});
                  }.bind(this),4000);
           }
     }


      handleChangeEmail(event) {
               this.setState({email: event.target.value});
     }

     handleChangePassword(event) {
              this.setState({password: event.target.value});
    }
	
    handleChangeNewPassword(event) {
             this.setState({new_password: event.target.value});
   }
	
	handleChangePCode(event) {
		var pcode = event.target.value;
		if (pcode.length > 6) 
		{
			 this.setState({pcode: pcode.slice(0, 6)});
		}
		else 
		{
			this.setState({pcode: pcode});
		}
              
    }
	
	


      handleChange(event) {
                 // this.setState({email: event.target.value});

		     const target = event.target;
		     const value = target.type === 'checkbox' ? target.checked : target.value;
		     const name = target.name;

		     this.setState({
		       [name]: value
		     });

      }

	/*SigninPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  login: PropTypes.func.isRequired
};
		*/

  render() {

    return (
    <Translation>
	    { t =>
	         <div>

			   <BrowserView>
			
			                {this.state.displayNewPasswordWrapper &&
						 <div style={{position: 'absolute', zIndex:99999, width: '100%', height: '100%', top: 0, left: 0, backgroundColor:  '#fff'}}>
			
			                               <div style={{clear: 'both', height: '50px'}}></div>
			
						          <div style={{textAlign:'center', marginBottom: 20,minHeight:70}}>
								<img src={'https://s3.amazonaws.com/debyooinc20.img/debyoo_big_icon.png'} alt="Debyoo Icon" style={{width:70}}/>
						          </div>
								
							    <div style={{clear: 'both', height: '10px'}}></div>	
							    <div style={{position:'relative', textAlign: 'center', color: '#333', fontSize: '18px', minHeight: '25px'}}>
								   <div style={{fontWeight: 'bold', fontSize: '25px'}}>{t('Create_a_new_password')}</div> 
								 
								  <div style={{clear: 'both', height: '10px'}}></div>
								
						              
									 
							   </div> 
								
								
				                   <div style={{position: 'relative', width: '370px',margin:'auto', height: '40px'}}>
				                                <Gradient style={{display: this.state.showPasswordError, position: 'absolute',zIndex:3,top:0,width:370}}
				                                    gradients={[
				                                         ['#CA1C01', '#BB1603'],
				                                       ]}
				                                          property="background"
				                                          duration={ 3000 }
				                                          angle="0deg"
				                                     className="buttonWhiteText">
				                                     {t('password_blank')}
				                                  </Gradient>

				                                  <Gradient style={{display: this.state.showPasswordTooShortError, position: 'absolute',zIndex:3,top:0,width:370}}
				                                    gradients={[
				                                         ['#CA1C01', '#BB1603'],
				                                       ]}
				                                          property="background"
				                                          duration={ 3000 }
				                                          angle="0deg"
				                                     className="buttonWhiteText">
				                                     {t('password_short')}
				                                    </Gradient>
						    </div>
		   
						   <div style={{clear: 'both', height: '10px'}}></div>
								
								
							<form onSubmit={this.onSubmitNewPassword} noValidate="novalidate"> 
								 <div style={{width:'370px',margin:'auto'}}>
				
								    <div style={{width: '370px',margin:'auto',}}>
								        <input className="basic_ti"  onChange={this.handleChangeNewPassword} value={this.state.new_password} 
								              style={{width: '354px',padding: '6px', borderColor: '#ccc'}}
										name="user[new_password]" placeholder={t('Type_a_new_password')} type="password" autoCapitalize="off" autoComplete="off" autoCorrect="off"/>
								     </div>
											  
								
				
								   <div style={{clear: 'both', height: '10px'}}></div>
											  
											  
								   
				
								      {this.state.displaySignButtons &&
				                                             <button style={{background: 'rgba(0,0,0,0)', border: 'none', padding: 0}}>
									           <Gradient style={{width:'370px', marginTop: 10}}
										   gradients={[
										   			['#1392E8', '#035598'],
										   		]}
									           property="background"
									           duration={ 3000 }
									           angle="0deg"
										    className="buttonWhiteText">
											  {t('Continue')}
									        </Gradient>
								           </button>
				                                         }

				                                   {!this.state.displaySignButtons && this.state.displayNewPassword &&
						                            <Gradient style={{width:'370px',  marginTop: 10}}
						                             gradients={[
						                             ['#1392E8', '#035598'],
						                             ]}
						                             property="background"
						                             duration={ 3000 }
						                             angle="0deg"
						                             className="buttonWhiteText">
						                             <div style={{paddingTop:3}}>
											  <Spinner color="#fff" size={14} speed={0.8}/>
										  </div>
						                          </Gradient>
						                        }
								
								   </div>
							
								</form>
								
								
								     <div style={{clear: 'both', height: '90px'}}></div>
											
									
								   <div style={{fontSize: '17px', margin:'auto', textAlign:'center'}}>
									  <a  href="/signin">{t('signin')}</a>&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;<a  href="/signup">{t('signup')}</a>
								   </div>	
												
												
												
								 <div style={{clear: 'both', height: '30%'}}></div>
								
				                             <div style={{width: 1020, margin: 'auto', textAlign: 'center'}}>
				                                  © {this.state.currentYear}  Debyoo
				                            </div>
												
							   <div style={{clear: 'both', height: '20px'}}></div>
								
								
						 </div>
					   }
			           
			                {this.state.displayPCodeWrapper &&
						 <div style={{position: 'absolute', zIndex:99999, width: '100%', height: '100%', top: 0, left: 0, backgroundColor:  '#fff'}}>
			
			                             <div style={{clear: 'both', height: '50px'}}></div>
			
						        <div style={{textAlign:'center', marginBottom: 20,minHeight:70}}>
								<img src={'https://s3.amazonaws.com/debyooinc20.img/debyoo_big_icon.png'} alt="Debyoo Icon" style={{width:70}}/>
						        </div>
								
							    <div style={{clear: 'both', height: '10px'}}></div>	
							    <div style={{position:'relative', textAlign: 'center', color: '#333', fontSize: '18px', minHeight: '25px'}}>
								   <div style={{fontWeight: 'bold', fontSize: '25px'}}>{t('resetpassword')}</div> 
								 
								  <div style={{clear: 'both', height: '30px'}}></div>
									 
								  <div style={{color: '#63ac24', display:this.state.showCodePopMessager}}>{t('A_code_has_been_sent')}</div>
								
				                             <Gradient style={{display: this.state.showPasswordCodeError, width:370, margin: 'auto'}}
											   gradients={[
											   			['#CA1C01', '#BB1603'],
											   		]}
										           property="background"
										           duration={ 3000 }
										           angle="0deg"
											    className="buttonWhiteText">
												  {t('You_entered_an_incorrect_code')}
				         				</Gradient>
								  
							   </div>
							    <div style={{clear: 'both', height: '40px'}}></div>
								
							<form onSubmit={this.onSubmitPCode} noValidate="novalidate">
								 <div style={{width:'250px',margin:'auto'}}>
								
								    <div style={{width: '162px',margin:'auto',}}>
								         <input className="basic_ti"  onChange={this.handleChangePCode} value={this.state.pcode} maxLength="6"
								              style={{width: '150px',padding: '6px', textAlign: 'center', borderColor: '#ccc', fontSize: '30px'}}
										name="user[pcode]" placeholder={'·  ·  ·  ·  ·  ·'} type="number" autoCapitalize="off" autoComplete="off" autoCorrect="off"/>
								     </div>
								
								   <div style={{clear: 'both', height: '10px'}}></div>
								
								      {this.state.displaySignButtons &&
			                                               <button style={{background: 'rgba(0,0,0,0)', border: 'none', padding: 0}}>
									           <Gradient style={{width:'250px', marginTop: 10}}
										   gradients={[
										   			['#1392E8', '#035598'],
										   		]}
									           property="background"
									           duration={ 3000 }
									           angle="0deg"
										    className="buttonWhiteText">
											  {t('Continue')}
									        </Gradient>
								           </button>
			                                           }

				                                   {!this.state.displaySignButtons && this.state.displayPCodeVerif &&
						                            <Gradient style={{width:'250px',  marginTop: 10}}
						                             gradients={[
						                             ['#1392E8', '#035598'],
						                             ]}
						                             property="background"
						                             duration={ 3000 }
						                             angle="0deg"
						                             className="buttonWhiteText">
						                             <div style={{paddingTop:3}}>
													 <Spinner color="#fff" size={14} speed={0.8}/>
											</div>
						                          </Gradient>
						                        }
												
								   </div>
											
								</form>
												
												
								  <div style={{clear: 'both', height: '70px'}}></div>
												
												
							       {this.state.displaySignButtons &&
								       <Gradient onClick={this.onResendPCode} style={{width:'250px', margin:'auto', marginTop: 10}}
									   gradients={[
									   			['#1392E8', '#035598'],
									   		]}
								           property="background"
								           duration={ 3000 }
								           angle="0deg"
									    className="buttonWhiteText">
										  {t('Send_code_again')}
								        </Gradient>
		                                            }

			                                   {!this.state.displaySignButtons && this.state.displayPCodeResend &&
					                            <Gradient style={{width:'250px',  margin: 'auto', marginTop: 10}}
					                             gradients={[
					                             ['#1392E8', '#035598'],
					                             ]}
					                             property="background"
					                             duration={ 3000 }
					                             angle="0deg"
					                             className="buttonWhiteText">
					                             <div style={{paddingTop:3}}>
												 <Spinner color="#fff" size={14} speed={0.8}/>
										</div>
					                          </Gradient>
					                        }	
											
											
								     <div style={{clear: 'both', height: '90px'}}></div>
											
									
								   <div style={{fontSize: '17px', margin:'auto', textAlign:'center'}}>
									  <a  href="/signin">{t('signin')}</a>&nbsp;&nbsp;&nbsp;·&nbsp;&nbsp;&nbsp;<a  href="/signup">{t('signup')}</a>
								   </div>	
												
												
												
								 <div style={{clear: 'both', height: '30%'}}></div>
								
				                             <div style={{width: 1020, margin: 'auto', textAlign: 'center'}}>
				                                  © {this.state.currentYear}  Debyoo
				                            </div>
												
							   <div style={{clear: 'both', height: '20px'}}></div>
											
			                </div>
					 }

					  <div className="main_header">
					        <div className="main_header_inside">
					           <div className="main_header_inside_left">
					                           <a href="/">
					                                <img src={'https://s3.amazonaws.com/debyooinc20.img/debyoo-logo-w300.png'} alt="Debyoo Logo"/>
							       </a>
					            </div>
													
								 <div className="main_header_inside_left_2">
														   
								       <a href="/song_page" className={this.props.pathname === '/song_page' ? 'activeHeaderLink' : 'nonactiveHeaderLink'}>Song Page</a>
								       <a href="/about/dshop" className={this.props.pathname === '/about/dshop' ? 'activeHeaderLink' : 'nonactiveHeaderLink'}>DShop</a>
									<a href="/about/dchat" className={this.props.pathname === '/about/dchat' ? 'activeHeaderLink' : 'nonactiveHeaderLink'}>DChat</a>
								   
								</div>
					       </div>
					     </div>


					     <div className="resetpswd_transp_wrapper">
						     <div className="sign_transp_inner">

							    <div style={{textAlign:'center', marginBottom: 20,minHeight:70}}>
									<img src={'https://s3.amazonaws.com/debyooinc20.img/debyoo_big_icon.png'} alt="Debyoo Icon" style={{width:70}}/>
							     </div>

								<div className="sign_title" style={{position: 'relative',width:370, minWidth:370, fontSize: 27}}>
									  {t('resetpassword')}

    								   <Gradient style={{display: this.state.showPasswordError, position: 'absolute',zIndex:3,top:0,width:370}}
    									   gradients={[
    									   			['#CA1C01', '#BB1603'],
    									   		]}
    								           property="background"
    								           duration={ 3000 }
    								           angle="0deg"
    									    className="buttonWhiteText">
    										  {t('email_not_found')}
    								  </Gradient>
											  
			                                <Gradient style={{display: this.state.showEmailInvalidError, position: 'absolute',zIndex:3,top:0,width:370}}
				                                   gradients={[
				                                       ['#CA1C01', '#BB1603'],
				                                     ]}
				                                        property="background"
				                                        duration={ 3000 }
				                                        angle="0deg"
				                                   className="buttonWhiteText">
				                                   {t('email_invalid')}
			                                  </Gradient>

									  <div style={{display: this.state.showPasswordSuccess, position: 'absolute',zIndex:3,top:-6,width:370, backgroundColor: '#4D8915', borderRadius: 4}}>
                                                                   <div style={{padding:10, color: '#fff', fontSize: 14}}>{t('you_will_receive_an_email_with_instructions')}</div>
									   </div>

								 </div>

							      <form onSubmit={this.onSubmit} noValidate="novalidate">

								 <input className="basic_ti"  onChange={this.handleChangeEmail} value={this.state.email}
								        name="user[email]" placeholder={'Email'} type="email" autoCapitalize="off" autoComplete="off" autoCorrect="off"/>


								      {this.state.displaySignButtons &&
                                                                 <button style={{background: 'rgba(0,0,0,0)', border: 'none', padding: 0}}>
      								           <Gradient style={{width:370, marginTop: 10}}
      									   gradients={[
      									   			['#1392E8', '#035598'],
      									   		]}
      								           property="background"
      								           duration={ 3000 }
      								           angle="0deg"
      									    className="buttonWhiteText">
      										  {t('reset')}
      								        </Gradient>
								           </button>
                                                             }

			                                   {!this.state.displaySignButtons &&
					                            <Gradient style={{width:370, marginTop: 10}}
					                             gradients={[
					                             ['#1392E8', '#035598'],
					                             ]}
					                             property="background"
					                             duration={ 3000 }
					                             angle="0deg"
					                             className="buttonWhiteText">
					                             <div style={{paddingTop:3}}>
												 <Spinner color="#fff" size={14} speed={0.8}/>
										</div>
					                          </Gradient>
					                        }

                       </form>



                       {this.state.displaySignButtons &&
                         <div>
          								  <a href="/signin">
          								     <Gradient style={{float: 'left', width:180, marginTop: 70}}
          									   gradients={[
          									   			['#FAD741', '#F3BA21'],
          									   		]}
          								           property="background"
          								           duration={ 3000 }
          								           angle="0deg"
          									    className="buttonBlackText">
          										  {t('signin')}
          								     </Gradient>
          								    </a>

          								    <a href="/signup">
          								       <Gradient style={{float: 'right', width:180, marginTop: 70}}
          									   gradients={[
          									   			['#1392E8', '#035598'],
          									   		]}
          								           property="background"
          								           duration={ 3000 }
          								           angle="0deg"
          									    className="buttonWhiteText">
          										  {t('signup')}
          								        </Gradient>
          								  </a>

          								 <div style={{clear: 'both'}}></div>

                     </div>
                    }

						      </div>
					      </div>


						<Fade {...fadeProperties}>

						      <div className="each-fade bg_jungle_day">
						        <div className="signin-image-container">
						            <img src={fadeImages[1]} />
						        </div>
						      </div>

						      <div className="each-fade bg_clean_mirror">
						        <div className="signin-image-container">
						          <img src={fadeImages[1]} />
						        </div>
						      </div>

						      <div className="each-fade bg_mole_hall">
						        <div className="signin-image-container">
						          <img src={fadeImages[1]} />
						        </div>
						      </div>

				      </Fade>

  			              <div className="home_footer_right">
  			                       © {this.state.currentYear}  Debyoo&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;<a href="javascript:void(0)" onClick={() => this.setLocale("en")}>{t('English')}</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="javascript:void(0)" onClick={() => this.setLocale("fr")}>{t('French')}</a>&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;<a href="/our_mission">{t('Our_mission')}</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="/contact">{t('Contact')}</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="/privacy">{t('Privacy')}</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="/terms">{t('Terms')}</a>
  			               </div>

		     	</BrowserView>


           </div>
	   }

      </Translation>
    );
  }

}

export default ResetpasswordPage;

//export default connect(null, { login })(SigninPage);
