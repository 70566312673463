import React from "react";
//import PropTypes from "prop-types";
//import { connect } from "react-redux";

import {  Link, withRouter } from 'react-router-dom';

import { inject, observer, Provider } from "mobx-react";
import { withTranslation, Translation, } from 'react-i18next';
import {BrowserView,MobileView,TabletView,} from 'react-device-detect';

import { sessionService } from 'redux-react-session';

import { connect } from "react-redux";

import { Gradient } from 'react-gradient';

import {generateIPForREST} from "../../../actions";

//import {user} from "../../../actions/user";

import {login} from "../../../actions/userAuth";
import setBgAction from "../../../actions/setBgAction";

import Spinner from 'react-activity/lib/Spinner';
import 'react-activity/lib/Spinner/Spinner.css';

//import MetaTags from 'react-meta-tags';
//import { login } from "../../actions/auth";

import { Fade } from 'react-slideshow-image';

const fadeImages = [
  'https://s3.amazonaws.com/debyooinc20.img/home/home_img_stories.png',
  'https://s3.amazonaws.com/debyooinc20.img/home/Publish_SP4_06.png',
  'https://s3.amazonaws.com/debyooinc20.img/home/home_img_feels4.png'
];

const fadeProperties = {
  duration: 3500,
  transitionDuration: 500,
  infinite: true,
  arrows: false,
  indicators: false
}

//@inject("appStore")
class SigninPage extends React.Component {

    constructor(props) {

          super(props);



   	 this.state = {
		 email: '',
		 password: '',
     showSigninError: 'none',
     displaySignButtons: true,
		 currentYear: new Date().getFullYear()
   	};

	this.handleChangeEmail = this.handleChangeEmail.bind(this);
	this.handleChangePassword = this.handleChangePassword.bind(this);

         this.onSubmit = this.onSubmit.bind(this);
	//this.handleSubmit = this.handleSubmit.bind(this);

     }
	 
	 
     componentDidMount() {
 		console.log("App. Store: " + JSON.stringify(this.props));
		
 		    this.props.setBgAction("white");
			
 			setTimeout (function(){
 				console.log(">>>>2. App. Store: " + JSON.stringify(this.props.location.search));
				
				var urlParams = new URLSearchParams(this.props.location.search);
				
			
			      console.log(">>>>3. App. Store: " + JSON.stringify(urlParams.has('after').toString()));
				console.log(">>>>3. App. Store: " + JSON.stringify(urlParams.get('after')));
				
 			}.bind(this),100);
     }


    setLocale(lang) {

  	  console.log("Locale Lang:" +lang)
         //  this.props.appStore.locale = lang;
    }
 /* submit = data =>
    this.props.login(data).then(() => this.props.history.push("/dashboard"));
    */
	
	checkStore() {
		console.log("Check store :" + JSON.stringify(this.props))
	}
	
    isEmail(email) {
       return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(email);
    }


    async onSubmit(event) {
          event.preventDefault();

          this.setState({
            displaySignButtons: false
          });
		  
	   var isValid = true;
	   isValid = isValid && this.isEmail(this.state.email);
	   
	   if (!isValid)
	   {
		           this.setState({showSigninError: 'block', displaySignButtons: true});
		           setTimeout(function(){
		             this.setState({showSigninError: 'none'});
		           }.bind(this),4000);
	   }
	   else
	  {
   	         var isPasswordValid = this.state.password.length > 0 ? true : false;
   	         isValid = isValid && isPasswordValid;
			 
		  if (!isValid)
		  {
	              this.setState({showSigninError: 'block', displaySignButtons: true});
	              setTimeout(function(){
	                this.setState({showSigninError: 'none'});
	              }.bind(this),4000);
		  }
		  else 
		  {
			  this.confirmOnSubmit();
		  }
	  }	  
		  
  }

  async confirmOnSubmit() {		


          try {
               console.log("submit");

              // var ip = 'https://www.debyoo.com/';

              //  var ip = 'http://localhost:3000/';

              var ip = generateIPForREST();

               let response = await fetch(ip+'signin_app_api', {
                                       method: 'POST',
                                       headers: {
                                         'Accept': 'application/json',
                                         'Content-Type': 'application/json',
                                       },
                                       credentials: 'include',
                                       body: JSON.stringify({
                                         user:{
                                           email: this.state.email,
                                           password: this.state.password,
                                         }
                                       })
                                     });
               let res = await response.json();

               if (response.status >= 200 && response.status < 300) {

                         if (res.status == "100")
                         {
                                console.log(">>> 100 HERE >>> " + JSON.stringify(res));

                               /* this.reactivateStep = 1;
                                 this.responseData = res;

                                this.setState({
                                  virtualjobvar: 'a',
                                  global_account_active_status: res.global_account_active_status
                                });*/
                         }
                          else if (res.status == "200")
                          {

                                 console.log(">>> 200 HERE >>> " + JSON.stringify(res));
					
								 
								 
				       this.props.login({type: 'USER_LOGGED_IN', user: res});
					   
					   

                                 this.setState({displaySignButtons: true});

                                 localStorage.setItem("jwtToken", res.jwt_token);
								 
					
								 
					    sessionService.saveSession({token: res.jwt_token, authenticated: true })
					         .then(() => {
					           sessionService.saveUser(res)
					           .then(() => {
					                    
										
								var urlParams = new URLSearchParams(this.props.location.search);
								
								if (urlParams.has('after'))
								{
									this.props.history.push(urlParams.get('after'));
								}
								else 
								{
									this.props.history.push('/home');
								}
				
			
								//	      console.log(">>>>3. App. Store: " + JSON.stringify(urlParams.has('after').toString()));
								//		console.log(">>>>3. App. Store: " + JSON.stringify(urlParams.get('after')));
										
										
					           }).catch(err => console.error(err));
					         }).catch(err => console.error(err));
								 
								 
							
				   

                               /*  store.setSelectedLocalePage('PasswordCode');
                                 this.props.navigation.navigate('PasswordCode', {email: this.state.email});

                                       this.setState({showProgress: false, showProgressWrapper: 'none', showPasswordButton: 'flex', showSignInButton: 'flex',
                                   showSignUpButton: 'flex',});


                                 this.setState({email: ''});*/
                         }
                         else
                         {
                            console.log(">>> Other HERE >>> " + JSON.stringify(res));
                            console.log(">>> response >>> " + JSON.stringify(response));
                           this.setState({showSigninError: 'block', displaySignButtons: true});
                           setTimeout(function(){
                             this.setState({showSigninError: 'none'});
                           }.bind(this),4000);

                           //this.setState({showProgress: false, showProgressWrapper: 'none', showPasswordButton: 'flex', showSignInButton: 'flex',
                            //  showSignUpButton: 'flex',});
                         }

               } else {
                          console.log("2.res: " + JSON.stringify(res));
                          console.log("2.response: " + JSON.stringify(response.status));
                               //Handle error
                              // let error = res;
                              // throw error;
                         this.setState({showSigninError: 'block', displaySignButtons: true});
                         setTimeout(function(){
                           this.setState({showSigninError: 'none'});
                         }.bind(this),4000);

                        // this.setState({showProgress: false, showProgressWrapper: 'none', showPasswordButton: 'flex', showSignInButton: 'flex',
                         //showSignUpButton: 'flex',});
               }



          } catch(error) {
                 console.log("error: " + JSON.stringify(error));

                 this.setState({showSigninError: 'block', displaySignButtons: true});
                 setTimeout(function(){
                   this.setState({showSigninError: 'none'});
                 }.bind(this),4000);
          }


     }




      handleChangeEmail(event) {
               this.setState({email: event.target.value});
     }

     handleChangePassword(event) {
              this.setState({password: event.target.value});
    }


      handleChange(event) {
                 // this.setState({email: event.target.value});

		     const target = event.target;
		     const value = target.type === 'checkbox' ? target.checked : target.value;
		     const name = target.name;

		     this.setState({
		       [name]: value
		     });

      }

	/*SigninPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  login: PropTypes.func.isRequired
};
		*/

  render() {

    return (
    <Translation>
	    { t =>
	         <div>

			   <BrowserView>

					  <div className="main_header">
					        <div className="main_header_inside">
					           <div className="main_header_inside_left">
					                         <a href="/">
					                                <img src={'https://s3.amazonaws.com/debyooinc20.img/debyoo-logo-w300.png'} alt="Debyoo Logo"/>
							          </a>
					            </div>
													
							 <div className="main_header_inside_left_2">
													   
							       <a href="/song_page" className={this.props.pathname === '/song_page' ? 'activeHeaderLink' : 'nonactiveHeaderLink'}>Song Page</a>
							       <a href="/about/dshop" className={this.props.pathname === '/about/dshop' ? 'activeHeaderLink' : 'nonactiveHeaderLink'}>DShop</a>
								<a href="/about/dchat" className={this.props.pathname === '/about/dchat' ? 'activeHeaderLink' : 'nonactiveHeaderLink'}>DChat</a>
							   
							</div>
					       </div>
					     </div>


					     <div className="sign_transp_wrapper">
						     <div className="sign_transp_inner">

							    <div  onClick={() => this.checkStore()} style={{textAlign:'center', marginBottom: 20,minHeight:70}}>
									<img src={'https://s3.amazonaws.com/debyooinc20.img/debyoo_big_icon.png'} alt="Debyoo Icon" style={{width:70}}/>
							     </div>

								 <div className="sign_title" style={{position: 'relative',width:370, minWidth:370}}>
									{t('signin')}

 								    <Gradient style={{display:this.state.showSigninError, position: 'absolute',zIndex:3,top:0,width:370}}
 									   gradients={[
 									   			['#CA1C01', '#BB1603'],
 									   		]}
 								           property="background"
 								           duration={ 3000 }
 								           angle="0deg"
 									    className="buttonWhiteText">
 										  {t('sign_in_error')}
 								     </Gradient>

								 </div>

                   <form onSubmit={this.onSubmit} noValidate="novalidate">

										  <input className="basic_ti_top"  onChange={this.handleChangeEmail} value={this.state.email} style={{width: '356px',padding: '6px'}}
      								        name="user[email]" placeholder={'Email'} type="email" autoCapitalize="off" autoComplete="off" autoCorrect="off"/>

      								   <input  className="basic_ti_bottom" name="user[password]" placeholder={t('Password')} onChange={this.handleChangePassword} value={this.state.password}
      								        style={{width: '356px',padding: '6px'}} type="password" autoCapitalize="off" autoComplete="off" autoCorrect="off"/>

                          {this.state.displaySignButtons &&
                            <button style={{background: 'rgba(0,0,0,0)', border: 'none', padding: 0}}>
                               <Gradient style={{width:370, marginTop: 10}}
          									   gradients={[
          									   			['#FAD741', '#F3BA21'],
          									   		]}
          								           property="background"
          								           duration={ 3000 }
          								           angle="0deg"
          									    className="buttonBlackText">
          										  {t('signin')}
          								      </Gradient>
                             </button>
                         }

                    </form>


                   {!this.state.displaySignButtons &&
                    <Gradient style={{width:370, marginTop: 10}}
                   gradients={[
                        ['#FAD741', '#F3BA21'],
                      ]}
                         property="background"
                         duration={ 3000 }
                         angle="0deg"
                    className="buttonBlackText">
                           <div style={{paddingTop:3}}>
						 <Spinner color="#000" size={14} speed={0.8}/>
				 </div>
                    </Gradient>
                  }

                    {this.state.displaySignButtons &&
                      <div>

          								    <div style={{textAlign:'center', marginTop: 20}}>
          										  <a href="/resetpassword" style={{color:'#fff', fontSize: 16, fontWeight: 900}}>{t('Forgot_password')}</a>
          								   </div>

          								     <a href="/signup">
          								       <Gradient style={{width:370, marginTop: 30}}
          									   gradients={[
          									   			['#1392E8', '#035598'],
          									   		]}
          								           property="background"
          								           duration={ 3000 }
          								           angle="0deg"
          									    className="buttonWhiteText">
          										  {t('signup')}
          								        </Gradient>
          								      </a>
                       </div>
                     }

						      </div>
					      </div>


						<Fade {...fadeProperties}>

						      <div className="each-fade bg_jungle_day">
						        <div className="signin-image-container">
						            <img src={fadeImages[1]} />
						        </div>
						      </div>

						      <div className="each-fade bg_clean_mirror">
						        <div className="signin-image-container">
						          <img src={fadeImages[1]} />
						        </div>
						      </div>

						      <div className="each-fade bg_mole_hall">
						        <div className="signin-image-container">
						          <img src={fadeImages[1]} />
						        </div>
						      </div>

				      </Fade>

  			              <div className="home_footer_right">
  			                       © {this.state.currentYear}  Debyoo&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;<a href="javascript:void(0)" onClick={() => this.setLocale("en")}>{t('English')}</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="javascript:void(0)" onClick={() => this.setLocale("fr")}>{t('French')}</a>&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;<a href="/our_mission">{t('Our_mission')}</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="/contact">{t('Contact')}</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="/privacy">{t('Privacy')}</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="/terms">{t('Terms')}</a>
  			               </div>

			</BrowserView>


           </div>
	 }

      </Translation>
    );
  }

}

const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
   setBgAction: (payload) => dispatch(setBgAction(payload)),
  login: (payload) => dispatch(login(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SigninPage));

//export default connect(null, { login })(SigninPage);
