export default function languageReducer (state = {language: "en"} , action)  {
  switch (action.type) {
    case "countryChange":
      return {
        ...state,
        language: action.payload
      };
    case "languageChange":
      return {
        ...state,
        language: action.payload
      };
    default:
      return state;
  }
};