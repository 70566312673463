import HomePage from "./components/pages/HomePage";

import HomeFeedPage from "./components/pages/home/HomeFeedPage";

import SigninPage from "./components/pages/auth/SigninPage";
import SignupPage from "./components/pages/auth/SignupPage";
import ResetpasswordPage from "./components/pages/auth/ResetpasswordPage";

var routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
   private: false,
   restricted: false,
  },
  {
    path: "/signin",
    name: "SigninPage",
    component: SigninPage,
   private: false,
   restricted: true,
  },
  {
    path: "/signup",
    name: "SignupPage",
    component: SignupPage,
   private: false,
   restricted: true,
  },
  {
    path: "/resetpassword",
    name: "ResetpasswordPage",
    component: ResetpasswordPage,
   private: false,
   restricted: true,
  }
];
export default routes;
