import React from 'react';
import PropTypes from 'prop-types';
import {  Link, NavLink, withRouter } from 'react-router-dom';
import { observable, computed, reaction,action,decorate } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import { withTranslation, Translation, } from 'react-i18next';
import { connect } from 'react-redux';
import {BrowserView,MobileView,TabletView, isMobileOnly, isIOS, isAndroid} from 'react-device-detect';
import { createBrowserHistory } from "history";

import { sessionService } from 'redux-react-session';

import { Gradient } from 'react-gradient';

import ProgressiveImage from 'react-progressive-image';

import { isLogin } from '../../utils';


import i18n from "../../i18n";

import {generateIPForREST} from "../../actions";

const history = createBrowserHistory();


const options = [
  'one', 'two', 'three'
];
const defaultOption = options[0];


/*
	<Route path="/" exact component={HomePage}/>
	<Route path="/signin" exact component={SigninPage}/>

        <h1>
		{t('greeting')}
        </h1>
        <button onClick={() => this.setLocale("en")} type="text">
          English
        </button>
        <button onClick={() => this.setLocale("uk")} type="text">
          Ukranian
        </button>
*/

//@withNamespaces()
//@inject("appStore")
//@observer
class HeaderConnectedPC extends React.Component {
	 
 constructor(props) {
		
       super(props);
	   
	//  console.log("0. authenticated:" +JSON.stringify(this.props))
	   
	 let path = this.props.history.location.pathname;
	 
	 console.log("Path:" +JSON.stringify(this.props));
	 
	 
	 let pathBool = true;
	 if (path.match('signin') || path.match('signup') || path.match('resetpassword') ||
          path.match('home'))
	{
		pathBool = false;
	}
	
			
	 this.state = {
		isActive: true,
		pageName: path,
	      showMainHeader: pathBool,
		 myProfileName: '',
		 myProfileSN: '',
		myProfileThumbnail: 'https://s3.amazonaws.com/debyooinc20.img/default_avatar_normal.jpg',
		myProfileThumbnailTiny: 'https://s3.amazonaws.com/debyooinc20.img/default_avatar_normal.jpg'
	};
	
	this.handleLinkClick = this.handleLinkClick.bind(this);
	this.onSearch = this.onSearch.bind(this);
	this.onNewStory = this.onNewStory.bind(this);
	
	this.onMenu = this.onMenu.bind(this);
  }
  
  componentWillMount() {
	  if (isLogin())
	  {
	  	 this.loadUserInfo();
		 
		// this.loadFeeds();
	  }
  }
  
  componentDidMount() {
  	
	 
	
	
	setTimeout (function(){
		//  console.log(">>>>Header: " +JSON.stringify(this.props.store))
	}.bind(this),1000);  
	
	 //  console.log("checked" +checked)
	
 }
 
 loadFeeds() {
 	  
	 
  	var ip = generateIPForREST();
	
  	
  	//  this.props.setLanguage(responseData.country_code);
  	fetch(ip+'load_home_posts_new_api', {
                              method: 'POST',
                              headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                              },
                              body: JSON.stringify({
					access_token: "M1JOC8YDWC4McotytKmJHg",
				       page: 0,
					limit: 1,
				       offset: 0,
					lang: "fr"
                              })
                      })
  	  	.then((response) => response.json())
  	  	.then((responseData) => {
  			console.log(">>> Feeds: " + JSON.stringify(responseData));
			
 			
               })
              .catch(function(error) {
			   
  	     }.bind(this));
		 
 }
 
 loadUserInfo() {
 	var ip = generateIPForREST();
	
 	var jwtToken = localStorage.getItem("jwtToken") || '';
	
	console.log('>>>  jwtToken: ' + jwtToken)
 	//  this.props.setLanguage(responseData.country_code);
 	fetch(ip+'load_basic_public_user_info_api', {
                             method: 'POST',
                             headers: {
                               'Accept': 'application/json',
 			          'Authorization': jwtToken,
                               'Content-Type': 'application/json'
                             },
 			   credentials: 'include',
                             body: JSON.stringify({
                             })
                     })
 	  	.then((response) => response.json())
 	  	.then((responseData) => {
 			console.log("Basic user info: " + JSON.stringify(responseData));
			
			if (responseData.auth == "1")
			{
			     this.setState({
				 myProfileName: responseData.user.name,
				 myProfileSN: responseData.user.screenname,
				myProfileThumbnail: responseData.user.picture,
				myProfileThumbnailTiny: responseData.user.picture_tiny,
			    });
			}
			else 
			{
				localStorage.removeItem('jwtToken');
				
		              sessionService.deleteSession();
		             sessionService.deleteUser();
			      
				  setTimeout (function(){
					  window.location.href = "/";
				  },10);
			}
			
              })
             .catch(function(error) {
			   
 	     }.bind(this));
 }
 
 
    onMenu(event) {
		console.log("Menu clicked")
	  //  this.props.callbackHeaderFromParent("search");
       }
 
 
 
      onSearch(event) {
	    this.props.callbackHeaderFromParent("search");
       }
 

      onNewStory(event) {
		 this.props.callbackHeaderFromParent("newstory");
	}

	handleLinkClick(event) {
	
	/*	setTimeout (function(){
			let path = this.props.history.location.pathname;
 
			 console.log(">>>2. Path:" +JSON.stringify(path));
 
 
			 let pathBool = true;
			 if (path.match('signin') || path.match('signup') || path.match('resetpassword'))
			{
				pathBool = false;
			} 
		
			 this.setState({
				pageName: path,
			      showMainHeader: pathBool
			});
		}.bind(this), 100)

	*/

	}
 
 

  
  setLocale(lang) {

	  console.log("Locale Lang:" +lang)
       //  this.props.appStore.locale = lang;
  }
  
  toggleMenu =  (e) => {
  	this.setState({
		isActive: !this.state.isActive	
  	});
  }
  
  
  
  
  

  render() {
	  
	//  const { t, i18n } = this.props;
	  
    return (
         <Translation>  
		{
		        t =>
			      <div>
			      
			          <BrowserView>
			
			
			          
	       		           <div className="main_header_connected">
		       		           <div className="main_header_inside">
			                                      {isLogin() &&
				       		             <div className="main_header_inside_left">			                                         
				                                      <Link to="/">
				                                           <img src={'https://s3.amazonaws.com/debyooinc20.img/debyoo-logo-w300.png'} alt="Debyoo Logo"/>
									  </Link>
							              </div>
								   }
			                                      {!isLogin() &&
				       		             <div className="main_header_inside_left">			                                         
				                                      <a href="/" onClick={this.handleLinkClick}>
				                                           <img src={'https://s3.amazonaws.com/debyooinc20.img/debyoo-logo-w300.png'} alt="Debyoo Logo"/>
									  </a>
							              </div>
								   }
														   
								    <div className="main_header_inside_center">
														   
										
														   
										<ul className="mainmenu">
										    <li className="liHome">
											<NavLink to="/home" className="navLinkinactive" activeClassName="navLinkactive">
											    <div style={{width:80}}>
											        <div className="navLinkIcon" style={{margin: 'auto',width:32, height: 32, textAlign:'center'}}>
												 </div>
												  <div className="navLinkLabel" style={{ marginTop:-2, fontSize:12, textAlign:'center'}}>
													  {t('Home')}
												   </div>
											     </div>
											</NavLink>
										   </li>
													  
										    <li className="liSearch">
											<a href="javascript:void(0)" onClick={this.onSearch} className="navLinkinactive">
											    <div className="navLinkLabel" style={{width:80}}>
											        <div className="navLinkIcon" style={{margin: 'auto',width:32, height: 32, textAlign:'center'}}>
												 </div>
												  <div className="navLinkLabel" style={{marginTop:-2, fontSize:12, textAlign:'center'}}>
													  {t('Search')}
												   </div>
											     </div>
											</a>
										   </li>
													  
										    <li className="liDChat">
											<NavLink to="/dchat" className="navLinkinactive" activeClassName="navLinkactive">
											    <div style={{width:80}}>
											        <div className="navLinkIcon" style={{margin: 'auto',width:32, height: 32, textAlign:'center'}}>
												 </div>
												  <div className="navLinkLabel" style={{color: '#777', marginTop:-2, fontSize:12, textAlign:'center'}}>
													  {'DChat'}
												   </div>
											     </div>
											</NavLink>
										   </li>
													  
													  
										    <li className="liNewStory">
											<a href="javascript:void(0)" onClick={this.onNewStory} className="navLinkinactive">
											    <div style={{width:80}}>
											        <div className="navLinkIcon" style={{margin: 'auto',width:32, height: 32, textAlign:'center'}}>
												 </div>
												  <div className="navLinkLabel" style={{color: '#777', marginTop:-2, fontSize:12, textAlign:'center'}}>
													  {t('New_story')}
												   </div>
											     </div>
											</a>
										   </li>
													  
													  
										    <li className="liDShop">
											<NavLink to="/dshop" className="navLinkinactive" activeClassName="navLinkactive">
											    <div style={{width:80}}>
											        <div className="navLinkIcon" style={{margin: 'auto',width:32, height: 32, textAlign:'center'}}>
												 </div>
												  <div className="navLinkLabel" style={{color: '#777', marginTop:-2, fontSize:12, textAlign:'center'}}>
													  {'DShop'}
												   </div>
											     </div>
											</NavLink>
										   </li>
													  
													  
										    <li className="liDPlay">
											<NavLink to="/dplay" className="navLinkinactive"  activeClassName="navLinkactive">
											    <div style={{width:80}}>
											        <div className="navLinkIcon" style={{margin: 'auto',width:32, height: 32, textAlign:'center'}}>
												 </div>
												  <div className="navLinkLabel" style={{marginTop:-2, fontSize:12, textAlign:'center'}}>
													  {'DPlay'}
												   </div>
											     </div>
											</NavLink>
										   </li>
													  
													  
									     </ul>	
														   
								</div>				   
   								  
								 
													  
								
								  <div onClick={this.onMenu} className="main_header_inside_right_1" style={{cursor:'pointer',width: 40}}>											
									   {1==2 &&
										   <img src={'https://s3.amazonaws.com/debyooinc20.img/nav/menu_dark.png'} style={{width:38}}/>	
										}							
								   </div>
								 
													  
								<Link to="/cart">    
								     <div className="main_header_inside_right_2" style={{width: 40, marginLeft:20}}>
									<img src={'https://s3.amazonaws.com/debyooinc20.img/nav/basket_icon_black.png'} style={{width:36}}/>		  
								   </div>
								</Link>
									
								<Link to="/notifications">  
								     <div className="main_header_inside_right_2" style={{width: 40, marginLeft:20}}>
									  <img src={'https://s3.amazonaws.com/debyooinc20.img/nav/worldblack.png'} style={{width:36}}/>		  
								   </div>
								</Link>
									
								<Link to={'/'+this.state.myProfileSN}>  
								    <div className="main_header_inside_right_2" style={{width: 'auto'}}>
									<div style={{width: 36, float: 'left'}}>
										<ProgressiveImage src={this.state.myProfileThumbnail} placeholder={this.state.myProfileThumbnailTiny} 
										        style={{width:36, borderRadius: 18, border:'1px solid #ccc'}}>
										  {src => {
										    return (
										      <div>
										        <img className="progressive-image" src={src} style={{width:36, borderRadius: 18, border:'1px solid #ccc'}} />
										        <noscript>
										          <img className="progressive-image no-script" src={this.state.myProfileThumbnail} style={{width:36, borderRadius: 18,  border:'1px solid #ccc'}}/>
										        </noscript>
										      </div>
										    );
										  }}
										</ProgressiveImage>
									</div>	  
									<div className="avatar_menu_name" 
									       style={{width: 'auto', maxWidth: 120, float: 'left', marginLeft: 5, color: '#333', fontSize: 15, height: 40}}>
									          {this.state.myProfileName}
									</div>
									<div style={{clear: 'both'}}/>
								   </div>
								</Link>
											 
								<div style={{clear: 'both'}}/>
																 							          
					           </div>
				           </div>
					
				    </BrowserView>
								   
					
					
		
			      </div>
			 
                  }
         </Translation>
     );
  }
}


/*
const mapStateToProps = state => ({
  ...state
});

const mapDispatchToProps = dispatch => ({
  setBgAction: (payload) => dispatch(setBgAction(payload)),
  setColorAction: (payload) => dispatch(setColorAction(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderConnectedPC));
*/

export default withRouter(HeaderConnectedPC);

