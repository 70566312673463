import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import { inject, observer, Provider } from "mobx-react";

import { sessionService, sessionReducer } from 'redux-react-session';

import configureStore from "./store";

import routes from "./routes";

import i18n from "./i18n";

import './app.scss';

import App from './App';



const hist = createBrowserHistory();

export var store = configureStore();

// Init the session service
sessionService.initSessionService(store);



ReactDOM.render(
 
       <Provider store={store} history={hist}>
	      <App store={store} history={hist}/>
        </Provider>,
  document.getElementById("root")
);