
import React, { Component } from "react";
import PropTypes from 'prop-types';

class DropdownMenuItem extends Component {
    propTypes: {
      action: PropTypes.func.isRequired,
      childrenProps: PropTypes.object,
      tabIndex: PropTypes.number,
      component: 'button',
      className: PropTypes.string
    }

   /* getDefaultProps() {
      return {
        tabIndex: 0,
        component: 'button',
        className: '',
        childrenProps: {}
      };  
    }*/

    handleKeyDown(e) {
      var key = e.which || e.keyCode;
      if(key === 32) { // spacebar
        e.preventDefault(); // prevent page scrolling
        this.props.action();
      }   
    }

    render() {
    //  var children = React.createElement(this.props.component, this.props.childrenProps, this.props.children);
      return (
        <li className={this.props.className} onClick={this.props.action}>
		  {this.props.children}
        </li>
      );  
    }
}


export default DropdownMenuItem;