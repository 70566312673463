import {generateIPForREST} from "../actions";

const TOKEN_KEY = 'jwtToken';
const RESERVED_PATHS = ['/', '/yinitj/join']
/*const RESERVED_PATHS = ['/home', '/welcome', '/dchat', '/dshop', '/dplay', '/cart', '/notifications', '/account', '/menu', '/blocked_users', '/payment_methods',
                                         '/contact', '/terms', '/privacy', '/eula', '/song_page', '/about/dshop', '/about/dchat', '/our_mission', 
                                        '/signin', '/signup', '/resetpassword']*/

export const login = () => {
    localStorage.setItem(TOKEN_KEY, 'TestLogin');
}

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
}

export const isLogin = () => {
    if (localStorage.getItem(TOKEN_KEY)) {
	console.log('Logged in')
        return true;
    }
   else
   {
   	console.log('Not logged in')
   }

    return false;
}



export const profileExists = (path) => {

  var sn = path.substr(1);
   console.log('User SN: ' + sn);
   
     var lastChar = sn.slice(-1);
     console.log('User SN Last char: ' + lastChar);
	 
      if (lastChar === "/")
	{
		sn =  sn.slice(0, -1);
	}
	
	var bool = checkProfil(sn);
	
	//  console.log('Final User SN: ' + sn);
	  
	  
   
     return bool;

  //  return false;
}

export const isReservedPath = (path) => {
   console.log('Rserved: ' + RESERVED_PATHS.indexOf(path));
    if (RESERVED_PATHS.indexOf(path) < 0)
   {
   	return false;
   }	
   else
   {
       return true;
   }

  //  return false;
}



/*
async checkProfil(sn) {	
	 try {		 
			 var ip = generateIPForREST();
		 
		         let response = await fetch(ip+'check_if_profile_exists_api', {
		                                 method: 'POST',
		                                 headers: {
		                                   'Accept': 'application/json',
		                                   'Content-Type': 'application/json',
		                                 },
		                                 credentials: 'include',
		                                 body: JSON.stringify({
							  sn: sn
		                                 })
		                               });
		         let res = await response.json();

		         if (response.status >= 200 && response.status < 300) {
					 
					 if (res.status == "200")
					 {
					 	  return true;	
					 }
					 else 
					 {
					 	  return false;	
					 }
		         }
			  else 
			 {
				  return false;	
			 }
			 	   
	 } catch(error) {
		 
		 return false;
	 }	   

}
*/