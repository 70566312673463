import React, { Component } from 'react';

import { observable, computed, reaction,action,decorate } from "mobx";
import { inject, observer, Provider } from "mobx-react";
import { withTranslation, Translation, } from 'react-i18next';

import { connect } from "react-redux";



import {Link} from "react-router-dom";



import {changeLanguage} from "../../../actions/changeLanguage";

//import MetaTags from 'react-meta-tags';

import {BrowserView,MobileView,TabletView,} from 'react-device-detect';
//isBrowser,isMobile, isTablet

import   FooterPC from "../../footer/FooterPC";
import HeaderConnectedPC from "../../header/HeaderConnectedPC";

//@inject("appStore")
//@observer
class HomeFeedPage extends Component {
	
    constructor(props) {
		
          super(props);
	   
   	  
		  
       //   console.log(">>>  store:" + JSON.stringify(store))
		  
		//  store.dispatch("Hello World")
			
   	 this.state = {
           backgroundColor: "black",
          activeColor: "info",
		 currentYear: new Date().getFullYear()
   	};
	
     }
	 
	 
    setLocale(lang) {

  	  console.log("Locale Lang:" +lang)
           this.props.appStore.locale = lang;
    }
	
	callbackHeader = (dataFromHeader) => {
		console.log("Good start but not yet there...: " + dataFromHeader);
		
		   this.props.relayCallbackHeaderFromParent(dataFromHeader);
     }
	
	
  render() {
	//console.log("isMobile:" + isMobile)
	  
  //	const { t, i18n } = useTranslation();

  /*	   const changeLanguage = lng => {
  	      i18n.changeLanguage(lng);
  	};*/
	  
    return (
    <Translation>
	    { t =>
	         <div>
          		
					<BrowserView>
			                    
						   {1==2 &&
							     <HeaderConnectedPC callbackHeaderFromParent={this.callbackHeader}/>
						   }
			
			                         <div style={{marginTop:100,textAlign:'center'}}>PC: Yeah, this is my logged in home feed page</div>
			
							 <div className="clear-both" style={{height: 100}}/>							  
							 
							 <FooterPC />
		
	
					</BrowserView>
			
			
			
			
			
					<MobileView>
			                        <div>Mobile: Yeah, this is my logged in home feed page</div>
			
					</MobileView>
			
			
			
			
					<TabletView>
					      <div>Tablet: Yeah, this is my logged in home feed page</div>
					</TabletView>
			        </div>
	  }
	 </Translation>
    )
  }
}




export default HomeFeedPage;


